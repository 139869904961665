import React, { Component } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import { isEmpty } from "lodash";

export default class Tag extends Component {
    static propTypes = {
        onGetColor: PropTypes.func,
        children: PropTypes.string.isRequired,
        color: PropTypes.string
    };
    static contextTypes = {
        $lumioApp: PropTypes.object,
        store: PropTypes.object
    };

    componentDidMount() {
        const { onGetColor, color, children } = this.props;
        if (isEmpty(color)) {
            onGetColor(children);
        }
    }

    render() {
        const { globalState: { tags: { tags } } } = this.context.$lumioApp;
        const { children, className, color } = this.props;
        const style = {
            backgroundColor: color || tags[children]
        };
        const css = [
            "tag-item",
            "Py(4px)",
            "Px(8px)",
            "Bdrs(4px)",
            "Mb(5px)",
            "Mend(3px)",
            "C(white)",
            "Lh(1.25)",
            "D(ib)",
            "Fz(0.8em)",
            "Fz(1em)--sm"
        ];
        return <div className={cs(css, className)} style={style} >{children}</div>;
    }
}
