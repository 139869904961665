import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import elementType from "prop-types-extra/lib/elementType";
import cs from "classnames";

import { childCss as inputGroupCss } from "./InputGroup";

export const childCss = [
    "form-control",
    "form-control--feedback_Pend(40px)",
    "form-control--success_Bdc(success)",
    "form-control--warning_Bdc(warning)",
    "form-control--error_Bdc(error)"
];

export default class FormControl extends Component {
    static propType = {
        component: elementType,
        /**
        * Only relevant if `component` is `'input'`.
        */
        type: PropTypes.string,
        /**
         * Uses `controlId` from `<FormGroup>` if not explicitly specified.
         */
        id: PropTypes.string,
        /**
         * Attaches a ref to the `<input>` element. Only functions can be used here.
         *
         * ```js
         * <FormControl inputRef={ref => { this.input = ref; }} />
         * ```
         */
        inputRef: PropTypes.func
    };

    state = {
        value: ""
    };

    static defaultProps = {
        component: "input"
    };

    static contextTypes = {
        $formGroup: PropTypes.object
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const { prevProps } = prevState;
        if (isEqual(nextProps, prevProps)) {
            return null;
        }

        return {
            value: nextProps.value || ""
        };
    }

    onChange = (e) => {
        e.preventDefault();
        const { onChange } = this.props;
        this.setState({ value: e.value });
        onChange(e);
    };

    render() {
        const formGroup = this.context.$formGroup;
        const controlId = formGroup && formGroup.controlId;

        const {
            component: InputComponent,
            type,
            id = controlId,
            inputRef,
            className,
            readOnly,
            onChange,
            disabled,
            value,
            ...props
        } = this.props;

        if (InputComponent !== "input" && InputComponent !== "password" && InputComponent !== "select") {
            return (
                <InputComponent
                    {...props}
                    value={this.state.value}
                    onChange={this.onChange}
                    id={id}
                    ref={inputRef}
                    readOnly={readOnly}
                    disabled={disabled}
                    className={className}
                    type={type}
                />
            );
        }

        const css = [
            ...childCss,
            ...inputGroupCss,

            "Px(12px)",
            "Py(12px)",
            "C(color)",
            "Bgi(n)",
            "Bgcp(pb)",
            "Bd",
            "Bdc(border-color-2)",
            "Bdrs(radius)",
            "D(b)",
            "W(100%)",
            "Mb(16px)"
        ];

        const disable = readOnly || disabled;
        const isInput = InputComponent === "input";
        const isFile = type === "file";
        const isSelect = InputComponent === "select";

        if (isSelect) {
            css.push("H(selectHeight)");
        }

        const cssCondition = {
            "O(n):f": isInput || isSelect,
            "Trs(inputFocuseTrs)": isInput || isSelect,
            "Bdc(inputBorderColorHover):f": isInput || isSelect,
            "Bxsh(inputBoxShadowHover):f": isInput || isSelect,
            "C(l-neutral-300)::ph": isInput || isFile || isSelect,
            "Bgc(#fff)": !disable,
            "Bgc(#ddd)": disable
        };

        return (
            <InputComponent
                {...props}
                value={this.state.value}
                onChange={this.onChange}
                type={type}
                id={id}
                ref={inputRef}
                readOnly={readOnly}
                disabled={disabled}
                className={cs(className, css, cssCondition)}
            />
        );
    }
}
