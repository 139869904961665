import moment from "moment";
import { isString, isEmpty, isNumber } from "lodash";
import { sprintf } from "sprintf-js";
import { default as UUID } from "uuid";

export function generateUuid() {
    return UUID.v4().replace(/-/g, "");
}

export function shortTitle(title, maxChar = 40) {
    if (!title || !isString(title)) {
        return "";
    }
    if (title.length > maxChar) {
        const midle = parseInt(maxChar / 2, 0);
        return title.slice(0, midle - 3) + "..." + title.slice(title.length - midle + 3, title.length);
    }
    return title;
}

export function secontToMoment(second) {
    if (!second) {
        second = 0;
    }
    const date = moment("2010-01-01").second(second);
    return date;
}

export function splitTimeInSecondToThreeSecment(second) {
    const h = Math.floor(second / 3600);
    const m = Math.floor((second - (3600 * h)) / 60);
    const s = second - (h * 3600) - (m * 60);

    return [h, m, s];
}

export function formatTime(second, renderHour = false) {
    const [h, m, s] = splitTimeInSecondToThreeSecment(second);
    if (h === 0 && !renderHour) {
        return sprintf("%02d:%02d", m, s);
    }
    return sprintf("%02d:%02d:%02d", h, m, s);
}

export function parseToSecond(momnt) {
    if (isString(momnt)) {
        momnt = momnt.split(":");
        return (parseInt(momnt[0], 10) * 3600) + (parseInt(momnt[1], 10) * 60) + parseInt(momnt[2], 10);
    }
    if (isNumber(momnt)) {
        return momnt;
    }
    return momnt.hour() * 3600 + momnt.minute() * 60 + momnt.second();
}

export function parseUrl(path) {
    // eslint-disable-next-line
    const appType = /\/app\/([a-zA-z0-9\-]*)\/([a-fA-F0-9]{3,6})(\/([a-zA-z0-9]{24}))?/; 
    let matches = null;
    // eslint-disable-next-line
    if ((matches = appType.exec(path)) !== null) { 
        return {
            parent_id: matches[4] || null,
            apps_type: matches[1],
            color: "#" + matches[2] || null
        };
    }

    const pattern = /\/([a-zA-z0-9]{24})/;
    // eslint-disable-next-line
    if ((matches = pattern.exec(path)) !== null) { 
        return {
            id: matches[1]
        };
    }

    return {};
}


export function isPortrait(width, height) {
    let portrait = true;
    if (height <= width) {
        portrait = false;
    }

    return portrait;
}

export function validateUrl(url) {
    // eslint-disable-next-line
    return (/^(http|https):\/\/[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/\?#[\]@!\$&'\(\)\*\+,;=.%]+$/.test(url)); 
}

class CDN {
    static hosts = [
        "cdn.lumio.id",
        "cdn1.lumio.id",
        "cdn2.lumio.id"
    ];

    static counter = 0;

    internalCounter = () => {
        if (CDN.counter >= CDN.hosts.length) {
            CDN.counter = 0;
        }
        return CDN.counter++;
    };

    getHosts = () => {
        const index = this.internalCounter();
        return CDN.hosts[index];
    };

    url = (url, shceme = "", options = []) => {
        const domain = this.getHosts();
        if (!isEmpty(shceme)) {
            shceme = `${shceme}:`;
        }

        options = [...options];

        // default options
        options.push("toauto");

        const pathOptions = options.join("/");

        return `${shceme}//${domain}/${pathOptions}/${url}`;
    };
}

export const cdn = new CDN();

export const formatDate = (date, format = "DD MMM YYYY") => {
    return moment(date).format(format).toString();
};
