import React, { Component } from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import { childCss as inputGroupCss } from "./InputGroup";
import { childCss as formControlCss } from "./FormControl";
export default class ShowablePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: "password"
        };
    }

    static propsTypes = {
        name: PropTypes.string.isRequired
    };

    static defaultProps = {

    };

    handleOnClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            type: this.state.type === "input" ? "password" : "input"
        });
    };

    render() {
        const {
            className,
            name,
            value,
            readOnly,
            disabled,
            ...props
        } = this.props;

        const css = [
            ...inputGroupCss,
            ...formControlCss,

            "Px(10px)",
            "Py(14px)",
            "C(color)",
            "Bgi(n)",
            "Bgcp(pb)",
            "Bd",
            "Bdc(inputBorderColor)",
            "Bdrs(3px)",
            "D(b)",
            "W(100%)",
            "Mb(inputMb)",

            "O(n):f",
            "Trs(inputFocuseTrs)",
            "Bdc(inputBorderColorHover):f",
            "Bxsh(inputBoxShadowHover):f",
            "C(placeholderColor)::ph"
        ];

        const cssCondition = {
            "Bgc(#fff)": !disabled,
            "Bgc(#ddd)": disabled
        };

        const cssPasswordShow = [
            "Pos(a)",
            "T(18px)",
            "End(15px)",

            "Fz(1em)",
            "Fw(600)",
            "Cur(p)"
        ];

        return (
            <div className="Pos(r)">
                <input
                    {...props}
                    className={cs(className, css, cssCondition)}
                    type={this.state.type}
                    name={name}
                    disabled={disabled}
                    readOnly={readOnly}
                />
                <span className={cs(className, cssPasswordShow)} onClick={this.handleOnClick}>{this.state.type === "input" ? "HIDE" : "SHOW"}</span>
            </div>
        );
    }
}
