import React from "react";
import { FormGroup, ControlLabel } from "../form";

// const Title = (props) => <div className="C(#979797)" >{props.children}</div>;

const Field = React.memo(({children, title, ...props}) => {
    return (
        <FormGroup {...props}>
            <ControlLabel>{title}</ControlLabel>
            {children}    
        </FormGroup>
    );
});

export default Field;
