import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";

export const POSITION = {
    TOP_LEFT: "top-left",
    TOP_RIGHT: "top-right",
    TOP_CENTER: "top-center",
    BOTTOM_LEFT: "bottom-left",
    BOTTOM_RIGHT: "bottom-right",
    BOTTOM_CENTER: "bottom-center"
};

export const TYPE = {
    INFO: "info",
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error",
    DEFAULT: "default"
};
export const ACTION = {
    SHOW: "SHOW_TOAST",
    CLEAR: "CLEAR_TOAST",
    MOUNTED: "CONTAINER_MOUNTED"
};

export default class Toast extends Component {
    render() {
        return (
            <ToastContainer
                position={POSITION.BOTTOM_RIGHT}
                autoClose={5000}
                newestOnTop={true}
                closeButton={false}
                closeOnClick
                pauseOnHover={true}
                toastClassName="Bdrs(radius)"
            />
        );
    }
}

Toast.notify = toast;
