import React, { Component } from "react";
import cs from "classnames";

import Icon from "./Icon";

export default class Block extends Component {
    render() {
        const {
            children,
            className,
            iconCss = "Fz(3em)--md  Fz(4em) D(b)",
            messageCss = "C(#ddd)"
        } = this.props;
        const css = [
            "Pos(f)",
            "T(0)",
            "B(0)",
            "Start(0)",
            "End(0)",
            "Bg(#000.5)",
            "Z(1000)",
            "D(f)",
            "Ai(c)",
            "Ac(c)",
            "Jc(c)",
            "C(#ffffff)",
            "Ta(c)",
            "Tsh(deep1)"
        ];

        return (
            <div className={cs(css, className)}>
                <div>
                    <Icon icon="lumio-logo" className={cs(iconCss)} />
                    <span className={cs(messageCss)}>{children}</span>
                </div>
            </div>
        );
    }
}
