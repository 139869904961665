import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

import Field from "./Field";
import { ButtonsGroup } from "..";
import DayPicker from "react-day-picker";
import {
    INHERITE,
    START_END_AT,
    START_AT,
    END_AT,
    getNewDateRangeValueFromState,
    getDefaultRangeState
} from "./util";

const scheduleButtons = [INHERITE, START_AT, END_AT, START_END_AT];

const getSelectedDays = (from, to, schedule) => {
    const now = moment().toDate();
    if (now > from) {
        from = now;
    }
    let selectedDays = [];
    switch (schedule) {
    case START_AT:
        selectedDays = from;
        break;
    case END_AT:
        selectedDays = to;
        break;
    case START_END_AT:
        selectedDays = [from, { from, to }];
        break;
    default:
        return;
    }

    return selectedDays;
};

const DateRangeField = (props) => {
    const { from, to, onChange } = props;

    const [schedule, setSchedule] = useState(getDefaultRangeState(from, to));
    const isFirstRun = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        onChange({ from: null, to: null });
    // eslint-disable-next-line
  }, [schedule]);

    const onDayClick = (value) => {
        const newValue = getNewDateRangeValueFromState(
            { from, to, schedule },
            value
        );

        if (newValue) {
            onChange(newValue);
        }
    };

    const selectedDays = getSelectedDays(from, to, schedule);
    const modifiers = { start: from, end: to };

    return (
        <Field title="Date">
            <ButtonsGroup
                list={scheduleButtons}
                type={schedule}
                onChange={setSchedule}
            />
            {schedule !== INHERITE && (
                <div className="D(f) Ai(c) Ac(c) Jc(c) Bgc(#fff) Bd Bdc(border-color-2) Bdrs(radius) Mt(8px) Mb(8px)">
                    <DayPicker
                        disabledDays={(day) => day <= moment().startOf("day")}
                        className="Selectable"
                        numberOfMonths={2}
                        selectedDays={selectedDays}
                        modifiers={schedule === START_END_AT ? modifiers : undefined}
                        onDayClick={onDayClick}
                    />
                </div>
            )}
        </Field>
    );
};

export default DateRangeField;
