import React, { Component } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import { isEqual } from "lodash";

import { cdn } from "../components/utility";

export default class Image extends Component {
    shouldComponentUpdate(nextProps) {
        return !isEqual(nextProps.src, this.props.src);
    }

    image = () => {
        const { src, alt, className, imgClassName, isThumbnail, cdnActive, ...props } = this.props;
        const css = [
            "D(f)",
            "Ai(c)",
            "Ac(c)",
            "Jc(c)"
        ];
        return (
            <div className={cs(css, className)} {...props}>
                <img src={this.toCDN(src)} alt={alt} className={cs(imgClassName)}/>
            </div>
        );
    };

    thumbnail = () => {
        const { src, alt, className, imgClassName, isThumbnail, cdnActive, ...props } = this.props;
        const css = [
            "Bgz(cv)",
            "Bgp(c)"
        ];
        const url = this.toCDN(src);
        const style = {
            backgroundImage: `url(${url})`
        };
        return (
            <div className={cs(css, className)} style={style} {...props} />
        );
    };

    toCDN = (url, shceme = "") => {
        const { options, cdnActive } = this.props;
        if (!cdnActive) {
            return url;
        }
        return cdn.url(url, shceme, options);
    };

    render() {
        const { isThumbnail } = this.props;
        if (isThumbnail) {
            return this.thumbnail();
        }
        return this.image();
    }
}

Image.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    cdnActive: PropTypes.bool,
    imgClassName: PropTypes.string,
    options: PropTypes.array,
    isThumbnail: PropTypes.bool
};

Image.defaultProps = {
    options: ["w600"],
    isThumbnail: true,
    cdnActive: true
};
