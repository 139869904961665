import React from "react";
import cs from "classnames";

import Dropdown from "../Dropdown";

export default class Hamburger extends Dropdown {
    render() {
        const { className, children } = this.props;

        const hamburgerCss = [
            "Pos(a)",
            "End(0)",
            "T(100%)",
            "Miw(200px)",
            "Trs(dropTransition)",
            "V(h)",
            "Z(-1)",
            "Op(0)",
            "Mah(0)",
            "TranslateY(-50%)",
            "Hamburger--active_V(v)",
            "Hamburger--active_Mah(1000px)",
            "Hamburger--active_Op(1)",
            "Hamburger--active_Z(1)",
            "Hamburger--active_TranslateY(1px)",
            "Hamburger--active_Trsde(transitionDelay)",
            "Hamburger--active_Bxsh(deep2)"
        ];

        const spanCss = [
            "D(b)",
            "Pos(a)",
            "H(2px)",
            "Bd",
            "Bdc(#fff)",
            "Bgc(#fff)",
            "Op(1)",
            "Start(18px)",
            "Bdrs(5px)",
            "End(18px)",
            "Rotate(0deg)",
            "Trsdu(.2s)",
            "Trstf(eio)"
        ];

        const conCss = {
            "Hamburger BdStart Bdc(#fff) D(ib) W(60px) Pos(r) Rotate(0deg) Trsdu(.5s) Trstf(eio) Cur(p)": true,
            "Hamburger--active": this.isActive()
        };
        return (
            <div className={cs(className, conCss)} >
                <div className="Z(1) H(100%)" onClick={this.onToggleClick}>
                    <span className={cs(spanCss, "T(20px) Hamburger--active_T(30px) Hamburger--active_Bdrs(200%) Hamburger--active_W(0%) Hamburger--active_Start(50%)")} />
                    <span className={cs(spanCss, "T(30px) Hamburger--active_Rotate(45deg)")} />
                    <span className={cs(spanCss, "T(30px) Hamburger--active_Rotate(-45deg)")} />
                    <span className={cs(spanCss, "B(20px) Hamburger--active_B(30px) Hamburger--active_W(0%) Hamburger--active_Bdrs(200%) Hamburger--active_Start(50%)")} />
                </div>
                <div className={cs(hamburgerCss)}>
                    {children}
                </div>
            </div>
        );
    }
}
