import React, { Component } from "react";
import PropTypes from "prop-types";
import cs from "classnames";

export default class Toggle extends Component {
    static propsTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.any,
        disable: PropTypes.bool,
        readOnly: PropTypes.bool,
        type: PropTypes.string
    };

    static defaultProps = {
        type: "medium"
    };

    render() {
        const {
            name,
            value,
            className,
            type,
            readOnly,
            disabled,
            onChange,
            ...props
        } = this.props;

        const toggleContainerCss = [
            "input-toggle",
            "P(2px)",
            "My(10px)",
            "Pos(r)",
            "D(f)",
            "Ai(c)",
            "Trsp(a)",
            "Trsdu(1s)",
            "Trstf(e)",
            "Cur(p)"
        ];

        const toggleCircleCss = [
            "circle-toggle",
            "Mx(1px)",
            "Bgc(white)",
            "Pos(a)",
            "Trsp(a)",
            "Trsdu(1s)",
            "Trstf(e)"
        ];

        const isActive = value;

        const toggleCircleCssCondition = {
            "H(28px)": type === "small",
            "W(28px)": type === "small",
            "Bdrs(28px)": type === "small",

            "H(34px)": type === "medium",
            "W(34px)": type === "medium",
            "Bdrs(34px)": type === "medium",

            "H(40px)": type === "large",
            "W(40px)": type === "large",
            "Bdrs(40px)": type === "large",

            "Start(0)": !isActive,
            "Start(30px)": isActive && type === "small",
            "Start(36px)": isActive && type === "medium",
            "Start(42px)": isActive && type === "large",

            "Pe(n)": disabled || readOnly
        };

        const toggleContainerCssCondition = {
            "Bgc(success)": isActive && !disabled && !readOnly,
            "Bgc(#ddd)": !isActive && !disabled && !readOnly,

            "H(30px)": type === "small",
            "W(60px)": type === "small",
            "Bdrs(30px)": type === "small",

            "H(36px)": type === "medium",
            "W(72px)": type === "medium",
            "Bdrs(36px)": type === "medium",

            "H(42px)": type === "large",
            "W(84px)": type === "large",
            "Bdrs(42px)": type === "large",

            "Pe(n)": disabled || readOnly,
            "Bgc(#e8e8e8)": disabled || readOnly
        };

        return (
            <div className={cs(toggleContainerCss, className, toggleContainerCssCondition)} {...props}>
                <div className = {cs(toggleCircleCss, toggleCircleCssCondition)} />
                <input
                    className = "V(h)"
                    {...props}
                    type="checkbox"
                    name={name}
                    disabled={disabled}
                    readOnly={readOnly}
                    value={value}
                />
            </div>
        );
    }
}
