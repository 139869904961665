import React, { Component } from "react";
import cs from "classnames";

export default class Devider extends Component {
    render() {
        const { label, className, ...cleanProps } = this.props;

        const liCss = ["H(1px)", "Bdtw(1px)", "Bdts(s)", "Bdtc(border-color-2)"];

        return <li className={cs(liCss, className)} {...cleanProps} />;
    }
}
