import React, { Component } from "react";
import PropTypes from "prop-types";
import cs from "classnames";

export default class ControlLabel extends Component {
    static propTypes = {
        /**
     * Uses `controlId` from `<FormGroup>` if not explicitly specified.
     */
        htmlFor: PropTypes.string
    };

    static contextTypes = {
        $formGroup: PropTypes.object
    };

    render() {
        const formGroup = this.context.$formGroup;
        const controlId = formGroup && formGroup.controlId;
        const { htmlFor = controlId, className, ...props } = this.props;

        const css = ["C(l-neutral-500)", "D(ib)", "Mb(8px)", "Fw(500)"];

        return (
            <label className={cs(css, className)} htmlFor={htmlFor} {...props} />
        );
    }
}
