import React from "react";
import PropTypes from "prop-types";
import { Header, Content, Footter, BaseModalComponent } from "../components/modal";
import { Button } from "./";
import cs from "classnames";

export default class Confirm extends BaseModalComponent {
    static propTypes = {
        title: PropTypes.any.isRequired,
        confirmLabel: PropTypes.string,
        cancelLabel: PropTypes.string,
        onConfirm: PropTypes.func.isRequired,
        onCancel: PropTypes.func,
        children: PropTypes.node.isRequired
    };

    static defaultProps = {
        title: "Confirmation Dialog",
        confirmLabel: "Confirm",
        cancelLabel: "Cancel",
        onConfirm: () => true,
        onCancel: () => false
    };

    onClickConfirm = (e) => {
        const { actions } = this.context.$lumioApp;
        actions.closeModal(this.props.onConfirm);
    };

    onClickCancel = (e) => {
        this.handleClose(e);
    };

    onClose = () => {
        return this.props.onCancel();
    };

    handleClose = (e) => {
        const { actions } = this.context.$lumioApp;
        e.preventDefault();
        actions.closeModal(this.props.onCancel);
    };

    render() {
        const { className, title, confirmLabel, cancelLabel, children } = this.props;

        return (
            <div className={ cs(className, "Bgc(#fff)")}>
                <Content className="BdT Bdc(border-color)">
                    {title !== false && <Header close={ this.handleClose } >{title}</Header>}
                    <div className="P(16px)">
                        {children}
                    </div>
                    <Footter className="D(f) Jc(fe) Px(16px) Py(12px)">
                        {cancelLabel && <Button onClick={this.onClickCancel} color="black" className="Mend(5px)">{cancelLabel}</Button>}
                        {confirmLabel && <Button onClick={this.onClickConfirm} color="primary">{confirmLabel}</Button>}
                    </Footter>
                </Content>
            </div>
        );
    }
}
