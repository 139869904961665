import React from "react";
import cs from "classnames";


const Card = React.forwardRef((props, ref)=> {
    const { className, ...restProps } = props;
    return (
        <div
            ref={ref}
            className={cs("Bd Bdc(#ffffff) Bgc(#ffffff) P(10px) P(16px)--md Bdrs(radius)", className)}
            {...restProps} />
    );
});

export default Card;
