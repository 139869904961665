import appSDK from "app-sdk";
import { isEmpty } from "lodash";

export const APP_READY = "APP_READY";
export const ORDER_MODE = "ORDER_MODE";
export const FETCH_DATA = "FETCH_DATA";
export const FETCH_DATA_FAILED = "FETCH_DATA_FAILED";
export const RECEIVE_DATA = "RECEIVE_DATA";

export const SAVE_DATA = "SAVE_DATA";
export const SAVE_DATA_FAILED = "SAVE_DATA_FAILED";
export const SAVE_DATA_SUCCEED = "SAVE_DATA_SUCCEED";

export const EXIT_APP = "EXIT_APP";
export const EXIT_APP_DONE = "EXIT_APP_DONE";
export const ON_ERROR = "ON_ERROR";

export const SET_NAME = "SET_NAME";
export const SET_DESCRIPTION = "SET_DESCRIPTION";
export const SET_DURATION = "SET_DURATION";
export const SET_CONFIGS = "SET_CONFIGS";
export const SET_CONTENTS = "SET_CONTENTS";
export const SET_BEFORE_SAVE_DATA = "SET_BEFORE_SAVE_DATA";

export const ADD_BUTTON = "ADD_BUTTON";

export const CLOSE_MODAL = "CLOSE_MODAL";
export const OPEN_MODAL = "OPEN_MODAL";

export const RENEW_AUTH_REQUESTED = "RENEW_AUTH_REQUESTED";
export const RENEW_AUTH = "RENEW_AUTH";

export const UPDATE_DETAIL = "UPDATE_DETAIL";

export const TAGS_COLOUR_FETCHING = "TAGS_COLOUR_FETCHING";
export const TAGS_COLOUR_FETACHED = "TAGS_COLOUR_FETACHED";
export const TAGS_COLOUR_FAILED = "TAGS_COLOUR_FAILED";

export const DEVICE_TAGS_FETCHING = "DEVICE_TAGS_FETCHING";
export const DEVICE_TAGS_LOADED = "DEVICE_TAGS_LOADED";

export const RPC_LIB_FETCHING = "RPC_LIB_FETCHING";
export const RPC_LIB_FETCHED = "RPC_LIB_FETCHED";
export const RPC_LIB_LOADED = "RPC_LIB_LOADED";

const saveSucceed = (app, isNew) => {
    return (dispatch) => {
        dispatch({ type: SAVE_DATA_SUCCEED, app });
        if (isNew) {
            isNew = false;
            window.history.pushState(null, app.name, "/" + app.id);
        }
        if (window.opener) {
            window.opener.postMessage({ action: "refresh" }, "*");
        }
    };
};

const saveFailed = (error) => {
    return {
        type: SAVE_DATA_FAILED,
        error
    };
};

const reciveData = (app) => {
    return {
        type: RECEIVE_DATA,
        data: app.data,
        app
    };
};

const fetchDataFailed = (error) => {
    return {
        type: FETCH_DATA_FAILED,
        error
    };
};

export const setName = (name) => {
    if (isEmpty(name)) {
        name = "Untitled";
    }
    return {
        type: SET_NAME,
        name
    };
};

const appReady = (app) => {
    return (dispatch) => {
        dispatch({ type: APP_READY, app });
        if (app.isNew) {
            dispatch(reciveData(app));
            dispatch(setName(""));
        }
    };
};

function exitApp() {
    return (dispatch) => {
        dispatch({
            type: EXIT_APP
        });
        setTimeout(() => {
            dispatch({
                type: EXIT_APP_DONE
            });
        }, 500);
    };
}

const getDetailByIdFromState = (id, getState) => {
    const state = getState();
    const { detail } = state.data;
    const detailById = detail[id];

    return detailById;
};

export const onError = (error) => {
    return {
        type: ON_ERROR,
        error
    };
};

export const setDescription = (description) => {
    return {
        type: SET_DESCRIPTION,
        description
    };
};

export const setDuration = (duration) => {
    return {
        type: SET_DURATION,
        duration
    };
};

export const setBeforeSaveData = (beforeSaveData) => {
    return {
        type: SET_BEFORE_SAVE_DATA,
        beforeSaveData
    };
};

export const setConfigs = (configs) => {
    return {
        type: SET_CONFIGS,
        configs
    };
};

export const setContents = (contents) => {
    return {
        type: SET_CONTENTS,
        contents
    };
};

export const addActionButton = (key, ob, callBack, app) => {
    return {
        type: ADD_BUTTON,
        payload: {
            key,
            ob,
            callBack
        },
        app
    };
};

export const closeModal = () => {
    return {
        type: CLOSE_MODAL

    };
};

export const openModal = (content, { isFixed = true, fullScreen = false, size = "" }) => {
    return {
        type: OPEN_MODAL,
        content,
        isFixed,
        fullScreen,
        size
    };
};

export const authRequested = () => {
    return {
        type: RENEW_AUTH_REQUESTED
    };
};

export const updateDetail = (detail) => {
    return {
        type: RENEW_AUTH_REQUESTED,
        detail
    };
};

export const toggleOrderMode = () => {
    return (dispatch, getState) => {
        const state = getState();
        const { orderMode } = state.sdk;
        dispatch({
            type: ORDER_MODE,
            orderMode: !orderMode
        });
    };
};

export const renewAuth = () => (dispatch) => dispatch({ type: RENEW_AUTH });

function handleError(error, cb, afterCb = () => { }) {
    return (dispatch, getState) => {
        const status = error.status;
        if (status === 401) {
            dispatch(renewAuth());
        } else {
            dispatch(cb(error));
        }
        afterCb(error);
        Promise.reject(error);
    };
}

export const getClipInfo = (id, forceUpdate = false) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let detailById = getDetailByIdFromState(id, getState);

            if (!isEmpty(detailById) && !forceUpdate) {
                return resolve(detailById);
            }

            if (detailById === null) {
                let interval = setInterval(() => {
                    detailById = getDetailByIdFromState(id, getState);
                    if (!isEmpty(detailById)) {
                        resolve(detailById);
                        clearInterval(interval);
                    }
                }, 1000);
                return;
            }
            dispatch(updateDetail({ [id]: null }));
            const state = getState();
            const { app } = state.sdk;

            app.getClipInfo(id).then((body) => {
                dispatch(updateDetail({ [id]: body }));
                resolve(body);
            }).catch((e) => dispatch(handleError(e, onError)));
        });
    };
};

export function initiateApp(configs, toast) {
    const toastRenew = () => {
        toast("Prepare for Auth");
    };
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (!isEmpty(configs.id)) {
                dispatch({
                    type: FETCH_DATA,
                    configs
                });
            } else if (!configs.apps_type || !configs.color) {
                const err = new Error("Apps type or color can not be null");
                dispatch(onError(err));
                return;
            }

            const state = getState();
            if (state.sdk.app && !state.sdk.error) {
                resolve(state.sdk.app);

                return;
            }
            const app = appSDK(configs);
            let isNew = app.isNew;
            return app
                .on(app.EVENTS.ON_DID_LOAD, () => dispatch(reciveData(app)))
                .on(app.EVENTS.ON_SAVE, () => dispatch({ type: SAVE_DATA, app }))
                .on(app.EVENTS.ON_SAVE_SUCCEED, () => {
                    dispatch(saveSucceed(app, isNew));
                    toast("Data Saved");
                })
                .on(app.EVENTS.ON_APP_READY, () => {
                    dispatch(appReady(app));
                    resolve(app);
                })
                .on(app.EVENTS.ON_SAVE_ERROR, e => dispatch(handleError(e, saveFailed, toastRenew)))
                .on(app.EVENTS.ON_LOAD_ERROR, e => dispatch(handleError(e, fetchDataFailed, toastRenew)))
                .on(app.EVENTS.ON_ERROR, e => dispatch(handleError(e, onError, toastRenew)))
                .on(app.EVENTS.ON_EXIT, () => dispatch(exitApp()));
        });
    };
}

export function getTagsColour(tags) {
    return (dispatch, getState) => {
        const { sdk: { app: { apiRequest } } } = getState();
        if (tags.length === 0) {
            return Promise.resolve();
        }

        dispatch({
            type: TAGS_COLOUR_FETCHING
        });

        return apiRequest.send("get", "/organization/content-tag/list", { slugs: tags.join(","), per_page: tags.length })
            .then(res => res.body)
            .then(body => body.list.reduce((map, obj) => {
                map[obj.slug] = obj.color;
                return map;
            }, {}))
            .then(result => dispatch({ type: TAGS_COLOUR_FETACHED, tags: result }))
            .catch(e => dispatch({ type: TAGS_COLOUR_FAILED, error: e }));
    };
}


export function loadDeviceTags() {
    return (dispatch, getState) => {
        const { sdk: { app: { apiRequest, account } }, deviceTags } = getState();
        const { loaded = false, fetching = false } = deviceTags;

        if (fetching) {
            return;
        }

        if (!loaded) {
            dispatch({ type: DEVICE_TAGS_FETCHING });

            return account.getOrganizationInfo().then((organization) => {
                let { oid } = organization;
                
                return apiRequest.send("get", "/organization/tag/list", {
                    per_page: 0,
                    organization_oid: oid
                })
                    .then(res => res.body)
                    .then(({ list }) => {
                        const data = list.map(({ slug }) => ({ value: slug, label: slug }));
                        dispatch({ type: DEVICE_TAGS_LOADED, data });
                    });
            });
        }
    };
}


export function fetchRpcLib(oids, type) {
    return (dispatch, getState) => {
        const { sdk: { app: { apiRequest } }, rpcLib } = getState();
        const { loaded = false, fetching = false } = rpcLib;

        if (fetching) {
            return;
        }

        if (!loaded) {
            dispatch({ type: RPC_LIB_FETCHING });

            if (isEmpty(oids)) {
                dispatch({ type: RPC_LIB_LOADED });
                return Promise.resolve();
            }

            return apiRequest.send("get", "/rpc/lookup/multi", {
                entity_oids: oids,
                entity_type: type
            })
                .then(res => res.body)
                .then((data) => {
                    dispatch({ type: RPC_LIB_FETCHED, data });
                });
        }
    };
}
