
import React, { Component } from "react";
import PropTypes from "prop-types";
import cs from "classnames";

export default class MenuItem extends Component {
    static defaultProps = {
        active: true
    };

    static propTypes = {
        active: PropTypes.bool,
        label: PropTypes.string,
        onClick: PropTypes.func,
        linkClassName: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        const { onClick, active, href = "" } = this.props;
        if (href === "" && typeof onClick === "function" && active) {
            e.preventDefault();
            onClick(e);
        }
    }

    render() {
        const { active, href = "", target, label, className, onClick, linkClassName, children, ...cleanProps } = this.props;
        const liCss = {
            "menu--active": active
        };

        return (
            <li className={cs(liCss, className)} {...cleanProps}>
                <a className={cs(linkClassName)} href={href} target={target} onClick={this.handleClick}>
                    {label || children}
                </a>
            </li>
        );
    }
}
