import React, { Component } from "react";
import PropTypes from "prop-types";
import Radio from "./Radio";

export default class RadioGroup extends Component {
    static propsTypes = {
        data: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.any.isRequired
        })).isRequired,
        name: PropTypes.string.isRequired
    };

    static defaultProps = {

    };

    handleOnChange = (value) => {
        const { onChange = (() => {}) } = this.props;
        return (e) => {
            e.preventDefault();
            onChange(value);
        };
    };

    render() {
        const { data, value, name } = this.props;
        const list = data.map((option) => {
            const radioProps = {
                key: option.value,
                value: option.value,
                name: name,
                checked: option.value !== value ? undefined : true,
                onChange: this.handleOnChange(option.value)
            };
            return <Radio {...radioProps}>{option.label}</Radio>;
        });

        return (
            <div>
                {list}
            </div>
        );
    }
}
