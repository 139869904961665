import React, { Component } from "react";
import PropTypes from "prop-types";
import cs from "classnames";

export default class CheckBox extends Component {
    static propType = {
        inputRef: PropTypes.func,
        inline: PropTypes.bool
    };

    static defaultProps = {
        inline: false,
        disabled: false
    };
    static contextTypes = {
        $formGroup: PropTypes.object
    };

    render() {
        const formGroup = this.context.$formGroup;
        const controlId = formGroup && formGroup.controlId;

        const {
            children,
            id = controlId,
            name,
            className,
            inline,
            inputRef,
            disabled,
            defaultChecked,
            onChange,
            containerCheckBoxCss,
            ...props
        } = this.props;

        const containerCss = {
            "D(ib)": inline
        };

        const checkBoxLabelCss = [
            // "Fz(1.4em)"
        ];

        const defaultContainerCheckBoxCss = {
            "Mend(15px)": true,
            "W(18px)": true,
            "H(18px)": true,
            "Bdrs(3px)": true,
            Bd: true,
            "Bdc(inputBorderColor)": true,

            "D(f)": true,
            "Ai(c)": true,
            "Jc(c)": true,

            "Bgc(#efefef)": disabled,
            "Bgc(primary)": defaultChecked,
            "Bdc(primary)": defaultChecked
        };

        const checkBoxMarkedCss = {
            "D(n)": !defaultChecked,
            "C(#dedede)": disabled,
            "C(white)": defaultChecked,
            "Op(.5)": defaultChecked && disabled
        };

        props.defaultChecked = defaultChecked;

        return (
            <label
                htmlFor={id}
                className={cs(containerCss, className, "D(f) Ai(c) Cur(p) Mb(inputMb)")}
                onClick={
                    disabled
                        ? () => {
                            return true;
                        }
                        : onChange
                }
            >
                <div className={cs(defaultContainerCheckBoxCss, containerCheckBoxCss)}>
                    <div className={cs(checkBoxMarkedCss)}>&#x2713;</div>
                </div>

                <div className={cs(checkBoxLabelCss)}>{children}</div>
                <input
                    {...props}
                    id={id}
                    type="checkbox"
                    className="V(h)"
                    ref={inputRef}
                    disabled={disabled}
                />
            </label>
        );
    }
}
