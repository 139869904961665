import React, { Component } from "react";
import cs from "classnames";

import "./css/form.scss";

export default class Form extends Component {
    render() {
        const { className, ...props } = this.props;
        const css = [
            // "Fz(0.625rem)"
        ];
        return (
            <form
                className = {cs(css, className)}
                {...props}
            />);
    }
}
