import React, { Component } from "react";
import cs from "classnames";

import Addon from "./InputGroupAddon";
import Button from "./InputGroupButton";

export const childCss = [
    "form-input-group_Mb(0)",
    "form-input-group_Bdendw(1px):lc",
    "form-input-group_Bdrs(radiusLeft):fc",
    "form-input-group_Bdrs(radiusRight):lc",
    "form-input-group_Bdstartw(1px):fc",
    "form-input-group_Bdrs(radius)!:oc",
    "form-input-group_Bdrs(0)"
];

export default class InputGroup extends Component {
    render() {
        const { className, ...props } = this.props;
        const css = [
            "form-input-group",
            "D(f)",
            "Pos(r)",
            "Ai(st)",
            "W(100%)",
            "Mb(inputMb)"
        ];
        return <div className={cs(css, className)} {...props} />;
    }
}

InputGroup.Button = Button;
InputGroup.Addon = Addon;
