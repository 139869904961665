import moment from "moment";

export const INHERITE = "Inherit";
export const START_AT = "Start";
export const SET_CUSTOME = "Custom";
export const END_AT = "End";
export const START_END_AT = "Range";
export const DEFAULT = "Default";


export const getDefaultRangeState = (start_at, end_at) => {
    let schedule = INHERITE;
    if (start_at && !end_at) {
        schedule = START_AT;
    } else if (!start_at && end_at) {
        schedule = END_AT;
    } else if (start_at && end_at) {
        schedule = START_END_AT;
    }
    
    return schedule;
};


export const getNewDateRangeValueFromState = (state, value) => {
    const now = moment().startOf("day");
    if (value < now) {
        return;
    }
    let { from, to, schedule} = state;
    switch (schedule) {
    case START_AT:
        from = value;
        to = undefined;
        break;
    case END_AT:
        from = undefined;
        to = value;
        break;
    case START_END_AT:
        if (from && to) {
            from = value;
            to = undefined;
        } else if (!from) {
            from = value;
        } else {
            to = value;
        }
        if (to && from > to) {
            const tmp = from;
            from = to;
            to = tmp;
        }

        break;
    default:
        break;
    }

    return {from, to};
};
