import React, {useContext, useCallback, useState, useEffect, createRef} from "react";
import Select from "react-select";
import isEmpty from "lodash/isEmpty";
import AsyncSelect from "react-select/async";
import Field from "./Field";
import { LumioAppContext } from "../../LumioContex";
import { loadDeviceTags } from "../../store/actions";
// import AsyncSelect from "react-select/async";

const optionsDeviceTagsType = [
    {value: "match_any", label: "Match Any"},
    {value: "match_all", label: "Match All"},
    {value: "exclude_any", label: "Exclude Any"},
    {value: "exclude_all", label: "Exclude All"}
];


const filterValue = (inputValue, data) => data.filter(({value}) => value.toLowerCase().includes(inputValue.toLowerCase()));


const loadAllDeviceTagsOption = ({dispatch, getState}) => {
    return (inputValue, callback) => {
        const {deviceTags: {loaded, fetching = false}} = getState();
        if (!loaded && !fetching) {
            dispatch(loadDeviceTags()).then(() => {
                const {deviceTags: {data}} = getState();
                callback(filterValue(inputValue, data));
            });
        }

        const intervalId = setInterval(()=> {
            const {deviceTags: {loaded: newLoaded, data}} = getState();
            if (newLoaded) {
                callback(filterValue(inputValue, data));
                clearInterval(intervalId);
            }
        }, 500);
    };
}; 
const multipleValueTags = (values) => values.map((value) => ({value: value, label: value}));

export const findTagsFilterTypeValue = (defaultValueType) => optionsDeviceTagsType.find(({value}) => value === defaultValueType);

const DeviceTagsFilterField = (props) => {
    const {defaultValueType, defaultValueTags = [], onChange} = props;
    const tagsSelect = createRef();
    const [type, setType] = useState(defaultValueType);
    const [tags, setTags] = useState(defaultValueTags);
    const [isDisabled, setIsDisable] = useState(true);

    const {store} = useContext(LumioAppContext); 

    const mappingTagValues = useCallback(
        () => {
            return multipleValueTags(defaultValueTags);
        },
        [defaultValueTags]
    );
    
    const handleChangeType = (value) => {
        if (!isEmpty(value)) {
            setType(value.value);    
            return;
        } 
        setType("");
        tagsSelect.current.select.select.clearValue();
    };

    const handleChangeTags = (values) => {
        if (!isEmpty(values)) {
            setTags(values.map(({value}) => value));
            return;
        }
        setTags([]);
    };

    useEffect(() => {
        if (defaultValueType !== type || defaultValueTags !== tags ) {
            onChange({type: tags.length > 0 ? type : null, tags: [...tags]});
        }

        if (!isEmpty(type)) {
            setIsDisable(false);
        } else {
            setIsDisable(true);
        }

        // eslint-disable-next-line
    }, [type, tags]);
    

    const defaultType = useCallback(() => findTagsFilterTypeValue(defaultValueType), [defaultValueType]);
    const tagsValues = mappingTagValues();
    return (
        <Field title="Device Tags" >
            <div className="D(f) W(100%) Fld(c) Fld(r)--md">
                <Select 
                    menuPlacement="top"
                    placeholder="Select Type..."
                    className="Pend(10px)--md W(30%)--md W(100%) Mb(10px)" 
                    options={optionsDeviceTagsType}
                    onChange={handleChangeType}
                    defaultValue={defaultType()}
                />
                <AsyncSelect 
                    ref={tagsSelect}
                    className="W(70%)--md W(100%) Mb(10px)" 
                    isMulti
                    defaultOptions
                    isDisabled={isDisabled}
                    placeholder="Choose Tags..."
                    menuPlacement="top"
                    onChange={handleChangeTags}
                    loadOptions={loadAllDeviceTagsOption(store)}
                    defaultValue={tagsValues}
                />
            </div>
        </Field>
    ); 
};

export default DeviceTagsFilterField;
