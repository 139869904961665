import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import { findDOMNode } from "react-dom";

import { cdn } from "../components/utility";

import { Image as ImageComponent, Video as VideoComponent } from ".";
import { Header, Content, BaseModalComponent } from "./modal";

export default class MediaModal extends BaseModalComponent {
    static contextTypes = {
        $lumioApp: PropTypes.object
    };

    state = {
        width: 0,
        imageLoaded: false,
        height: 0
    };

    static defaultProps = {
        cdnActive: true
    };

    static propTypes = {
        type: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        cdnActive: PropTypes.bool,
        cover_url: PropTypes.string
    };

    componentDidMount() {
        this.getDimentionFromURL().then(({ width, height }) => {
            this.setState({ width, height, imageLoaded: true });
        });
    }

    handleOnClickContainer = (event) => {
        const element = findDOMNode(this.element);
        if (event.target === element) {
            this.handleClose(event);
        }
    };

    render() {
        const { className, type, url, title, cover_url, cdnActive } = this.props;
        const { height, width, imageLoaded } = this.state;
        if (!imageLoaded) {
            return "";
        }
        let content = null;
        let potrait = true;
        if (width >= height) {
            potrait = false;
        }

        if (type === "image") {
            const imageCss = {
                "Mih(1vh) Maw(90%) H(100%) H(100%)--sm H(90%)--xl": potrait,
                "W(100%) W(60%)--xl W(70%)--lg W(80%)--md ": !potrait
            };
            content = (<ImageComponent
                cdnActive={cdnActive}
                src={url}
                alt={title}
                className={cs(
                    {
                        "H(100%)": !potrait,
                        "Maw(100%) H(100%) Mih(1vh)": potrait
                    }
                )}
                ref={ref => this.element = ref}
                imgClassName={cs(imageCss)}
                isThumbnail={false}
                options={[potrait ? "w550" : "w1000"]}
                onClick={this.handleOnClickContainer}
            />);
        } else if (type === "video") {
            content = (
                <VideoComponent
                    poster={cover_url}
                    className={cs(
                        {
                            "H(100%)": !potrait,
                            "Maw(100%) H(100%)": potrait
                        }
                    )}
                    ref={ref => this.element = ref}
                    src={url}
                    onClick={this.handleOnClickContainer}
                />);
        }

        return (
            <div className={cs(className, "Bgc(#000.5)")}>
                <Content>
                    <Header close={this.handleClose} className="C(#fff)">{title}</Header>
                    {content}
                </Content>
            </div>
        );
    }

    getDimentionFromURL = () => {
        const { url, type, cover_url, cdnActive } = this.props;
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.addEventListener("load", () => {
                resolve({ width: img.width, height: img.height });
            });

            if (type === "image") {
                img.src = cdnActive ? cdn.url(url) : url;
            } else if (type === "video") {
                img.src = cdnActive ? cdn.url(cover_url) : cover_url;
            }
        });
    };
}
