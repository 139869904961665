import React, { createContext, useContext } from "react";
import { bindActionCreators } from "redux";
import {ReactReduxContext} from "react-redux";

export const LumioAppContext = createContext({
    store: undefined,
    $lumioApp: {}
});

export const LumioApp = ({lumioApp, children}) => {
    const {store} = useContext(ReactReduxContext);
    return (
        <LumioAppContext.Provider value={{store, ...lumioApp}} >
            {children}
        </LumioAppContext.Provider>
    );
};

export const LumioReducerConsumer = (actions) => {
    return (props) => {
        const { dispatch, children, ...newProps } = props;
        const registeredActions = bindActionCreators(actions, dispatch);

        const childrenProps = {
            ...newProps,
            ...registeredActions
        };

        return (
            <LumioAppContext.Consumer>
                {(value) => children({ ...childrenProps, ...value})}
            </LumioAppContext.Consumer>
        );
    };
};
