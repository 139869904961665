import React, { Component } from "react";
import cs from "classnames";

export default class Title extends Component {
    render() {
        const { className, ...props } = this.props;
        return (
            <div
                className={cs("Fz(1.2rem) Fw(400)", className)}
                {...props}
            />
        );
    }
}
