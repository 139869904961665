import React, { Component } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import moment from "moment";
import { isEqual } from "lodash";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";

import { InputGroup } from ".";
import { childCss as inputGroupCss } from "./InputGroup";
import { childCss as formControlCss } from "./FormControl";

export default class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState(props);
    }

    getInitialState(props) {
        const { from, to } = props;
        return {
            isActive: false,
            from: from ? moment(from).toDate() : "",
            to: to ? moment(to).toDate() : ""
        };
    }

    handleDayClick = (day) => {
        const range = DateUtils.addDayToRange(day, this.state);
        this.setState(range);
    };

    componentDidUpdate(prevProps, prevState) {
        const { onChange } = this.props;
        if (!isEqual(prevProps, this.props)) {
            this.getInitialState(this.props);
        } else if (!isEqual(prevState, this.state)) {
            const { from, to } = this.state;
            if (from && to) {
                onChange({
                    from,
                    to
                });
            }
        }
    }

    handleOnClickInput = (e) => {
        document.addEventListener("click", this.handleOutsideClickInput, false);

        this.setState({
            isActive: true
        });
    };

    handleOutsideClickInput = (e) => {
        // ignore clicks on the component itself
        if (this.node.contains(e.target)) {
            return;
        }

        document.removeEventListener("click", this.handleOutsideClickInput, false);
        this.setState({
            isActive: false
        });
    };

    handleChangeFrom = (e) => {
        this.setState({ from: e.target.value });
    };

    handleChangeTo = (e) => {
        this.setState({ to: e.target.value });
    };

    handleClearClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { onChange } = this.props;
        const from = "";
        const to = "";
        onChange({
            from,
            to
        });

        this.setState({
            from: "",
            to: ""
        });

        document.removeEventListener("click", this.handleOutsideClickInput, false);
        this.setState({
            isActive: false
        });
    };

    static propsTypes = {
        onChange: PropTypes.func
    };

    static defaultProps = {
        readOnly: true,
        format: "D MMMM YYYY",
        placeholder: "Pick a date",
        numberOfMonths: 2,
        onChange: () => true
    };

    render() {
        const {
            readOnly,
            format,
            className,
            placeholder
        } = this.props;

        const css = [
            ...formControlCss,
            ...inputGroupCss,

            "Px(10px)",
            "Py(14px)",
            "C(color)",
            "Bgi(n)",
            "Bgcp(pb)",
            "Bd",
            "Bdc(inputBorderColor)",
            "Bdrs(3px)",
            "D(b)",
            "W(100%)",
            "Mb(inputMb)",

            "O(n):f",
            "Trs(inputFocuseTrs)",
            "Bdc(inputBorderColorHover):f",
            "Bxsh(inputBoxShadowHover):f",
            "C(placeholderColor)::ph",

            "Cur(p)"
        ];

        const { isActive, from, to } = this.state;
        const modifiers = { start: from, end: to };

        const dateRangeCss = [
            "Pos(a)",
            "Start(0)",
            "Z(1000)",
            "Bgc(#fff)",
            "Bxsh(BtnBoxShadow)",
            "Fz(1.4em)",
            "Mb(50px)"
        ];

        const dateRangeConditionCss = {
            "D(n)!": !isActive
        };

        return (
            <div className="Pos(r)" onClick={this.handleOnClickInput} ref={node => { this.node = node; }}>
                <InputGroup>
                    <input type="text"
                        className={cs(className, css)}
                        value = {from ? moment(from).format(format) : from}
                        placeholder={placeholder}
                        onChange={this.handleChangeFrom}
                        readOnly={readOnly}
                    />
                    <InputGroup.Addon> - </InputGroup.Addon>
                    <input type="text"
                        className={cs(className, css)}
                        value = {to ? moment(to).format(format) : to}
                        placeholder={placeholder}
                        onChange={this.handleChangeTo}
                        readOnly={readOnly}
                    />
                    <div className="clear Pos(a) T(18px) End(15px) Fz(1em) Fw(600) Cur(p) C(color)" onClick={this.handleClearClick}>CLEAR</div>
                </InputGroup>
                <DayPicker
                    className={cs("Selectable", dateRangeCss, dateRangeConditionCss)}
                    numberOfMonths={this.props.numberOfMonths}
                    selectedDays={[from, { from, to }]}
                    modifiers={modifiers}
                    onDayClick={this.handleDayClick}
                />
            </div>
        );
    }
}
