import React, { Component } from "react";
import PropTypes from "prop-types";

import cs from "classnames";

export default class OrderButton extends Component {
    static contextTypes = {
        $lumioApp: PropTypes.object
    };

    handelOrderMode = (e) => {
        e.preventDefault();
        const { actions } = this.context.$lumioApp;
        actions.toggleOrderMode();
    };

    render() {
        const { orderMode } = this.context.$lumioApp;
        const css = [
            "Fz(1rem)",
            "Cur(p)",
            "Bdrs(radius)",
            "Bd",
            "Bdc(border-color-2)",
            "Px(12px)",
            "Py(8px)",
            "C(color)",
            "C(primary):h",
            "Bdc(primary):h"
        ];
      
        const cssConditional = {
            "Z(901)": orderMode,
            "Pos(r)": orderMode,
            "Bgc(t)": !orderMode,
            "Bgc(#fff)": orderMode
        };
        return (
            <button
                color="transparent"
                className={cs(css, cssConditional)}
                onClick={this.handelOrderMode}
            >
                {orderMode ? "Done" : "Reorder"}
            </button>
        );
    }
}
