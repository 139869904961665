import React, { Component } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import Ink from "react-ink";

const defaultClassName = ["Pos(r)", "O(n)", "Cur(p)", "Bdrs(radius)"];

const disableCss = "Op(.6) Bxsh(n) Bdc(border-color)!:di C(l-neutral-200)!:di";

const css = {
    primary: ["Bgc(primary) Bgc(primary-400):h C(white) Bdc(primary) Bdc(primary-400):h Bds(s) Bdw(1px)"],
    default: ["Bgc(#fff) C(color) Bdc(border-color-2) Bds(s) Bdw(1px) Bdc(primary-400):h C(primary):h"],
    transparent: ["Bgc(t) C(color) Bdc(t) Bds(s)"]
};

const sizes = {
    large: ["Px(16px)", "Py(12px)", "Lh(2)", "Fz(1.1rem)"],
    medium: ["Px(16px)", "Py(8px)", "Lh(1.5)", "Fz(1rem)"],
    small: ["Px(10px)", "Py(6px)", "Fz(0.875rem)"]
};

export default class Button extends Component {
    get style() {
        const { disabled = false, color, size = "medium" } = this.props;
        let className = [];

        if (color && css[color] && !disabled) {
            className.push(css[color]);
        } else {
            className.push(css.default);
        }

        if (disabled) {
            className.push(disableCss);
        }

        className.push(sizes[size]);

        return cs(defaultClassName, className);
    }

    render() {
        let { className, color, disabled, ink, ...props } = this.props;
        return (
            <button
                className={cs(this.style, className)}
                disabled={disabled}
                {...props}
            >
                {this.props.children}
                {!disabled && ink && <Ink />}
            </button>
        );
    }
}

Button.propTypes = {
    color: PropTypes.string,
    ink: PropTypes.bool,
    sizes: PropTypes.oneOf(["large", "medium", "small"])
};

Button.defaultProps = {
    ink: true
};
