import React, { Component } from "react";
import isArray from "lodash/isArray";
import isFunction from "lodash/isFunction";
import PropTypes from "prop-types";
import { findDOMNode } from "react-dom";
import cs from "classnames";

import { Icon } from "./";

export default class Dropdown extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        align: PropTypes.oneOf(["right", "left"]),
        active: PropTypes.bool,
        onHide: PropTypes.func,
        onShow: PropTypes.func,
        children: PropTypes.node,
        className: PropTypes.string,
        label: PropTypes.any,
        removeElement: PropTypes.bool,
        style: PropTypes.object,
        activeOnHover: PropTypes.bool,
        toggleIcon: PropTypes.any,
        toggleCss: PropTypes.string
    };

    static defaultProps = {
        align: "right",
        toggleIcon: "more",
        toggleCss: "Mstart(8px)"
    };

    constructor(props) {
        super(props);

        this.state = {
            active: false
        };

        this.dropdown = null;

        this.onWindowClick = this.onWindowClick.bind(this);
        this.onToggleClick = this.onToggleClick.bind(this);
    }

    componentDidMount() {
        window.addEventListener("click", this.onWindowClick);
        window.addEventListener("touchstart", this.onWindowClick);
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.onWindowClick);
        window.removeEventListener("touchstart", this.onWindowClick);
    }

    onWindowClick(event) {
        const dropdownElement = findDOMNode(this);
        if (
            event.target !== dropdownElement &&
      !dropdownElement.contains(event.target) &&
      this.isActive()
        ) {
            this.hide();
        }
    }

    onToggleClick(event) {
        const { activeOnHover, disabled } = this.props;
        if (activeOnHover || disabled) {
            return;
        }
        event.preventDefault();
        if (this.isActive()) {
            this.hide();
        } else {
            this.show();
        }
    }

    isActive() {
        return typeof this.props.active === "boolean"
            ? this.props.active
            : this.state.active;
    }

    hide() {
        this.setState(
            {
                active: false
            },
            () => {
                if (this.props.onHide) {
                    this.props.onHide();
                }
            }
        );
    }

    show() {
        this.setState(
            {
                active: true
            },
            () => {
                if (this.props.onShow) {
                    this.props.onShow();
                }
            }
        );
    }

    render() {
        const {
            className,
            onHide,
            onShow,
            removeElement,
            label,
            activeOnHover,
            align,
            toggleIcon,
            toggleCss,
            ...cleanProps
        } = this.props;

        const { disabled } = this.props;
        const active = this.isActive();
        const containerCss = {
            dropdown: true,
            "dropdown--active": active,
            "dropdown--disabled": disabled
        };

        const newToggleCss = ["Cur(p)", toggleCss];

        let icon = toggleIcon;

        if (isArray(toggleIcon) && toggleIcon.length === 2) {
            icon = active ? toggleIcon[1] : toggleIcon[0];
        }

        return (
            <div className={cs(containerCss, className)} {...cleanProps}>
                <div className="D(f)">
                    {isFunction(label) && label(active, this.onToggleClick)}
                    {!isFunction(label) && label}
                    {toggleIcon !== false && (
                        <Icon
                            className={cs(newToggleCss)}
                            icon={icon}
                            onClick={this.onToggleClick}
                        />
                    )}
                </div>
                {this.renderChildren()}
            </div>
        );
    }

    renderChildren() {
        const { children, activeOnHover, align } = this.props;

        const contentCss = [
            "Pos(a)",
            "Trs(dropTransition)",
            "V(h)",
            "Z(-1)",
            "Op(0)",
            "H(a)",
            "Mah(0)",
            "Ov(a)",
            "TranslateY(-50%)",
            "Bdrs(radius)"
        ];

        const cssByCondition = {
            "End(0)": align === "right",
            "Top(100%)": true
        };

        const contentCssActive = [
            "dropdown--active_V(v)",
            "dropdown--active_Op(1)",
            "dropdown--active_Z(1)",
            "dropdown--active_Mah(450px)",
            "dropdown--active_TranslateY(0%)",
            "dropdown--active_Trsde(transitionDelay)",
            "dropdown--active_Bxsh(deep2)"
        ];

        const contentCssHover = [
            "dropdown:h_V(v)",
            "dropdown:h_Op(1)",
            "dropdown:h_Z(1)",
            "dropdown:h_TranslateY(0%)",
            "dropdown:h_Trsde(transitionDelay)",
            "dropdown:h_Bxsh(deep2)"
        ];

        return (
            <div
                className={cs(
                    contentCss,
                    cssByCondition,
                    activeOnHover ? contentCssHover : contentCssActive
                )}
                ref={(ref) => (this.dropdown = ref)}
            >
                {children}
            </div>
        );
    }
}
