import React, {useCallback, useState, useEffect, createRef} from "react";
import Select from "react-select";
import isEmpty from "lodash/isEmpty";
import CreatableSelect from "react-select/creatable";
import Field from "./Field";
// import AsyncSelect from "react-select/async";

const optionsDynamicTagsType = [
    {value: "match_any", label: "Match Any"},
    {value: "match_all", label: "Match All"},
    {value: "exclude_any", label: "Exclude Any"},
    {value: "exclude_all", label: "Exclude All"}
];

const multipleValueTags = (values) => values.map((value) => ({value: value, label: value}));

export const findDtagsFilterTypeValue = (defaultValueType) => optionsDynamicTagsType.find(({value}) => value === defaultValueType);

const DynamicTagsFilterField = (props) => {
    const {defaultValueType, defaultValueTags = [], onChange} = props;
    const tagsSelect = createRef();
    const [type, setType] = useState(defaultValueType);
    const [tags, setTags] = useState(defaultValueTags);
    const [isDisabled, setIsDisable] = useState(true);

    // const {store} = useContext(LumioAppContext); 

    const mappingTagValues = useCallback(
        () => {
            return multipleValueTags(defaultValueTags);
        },
        [defaultValueTags]
    );
    
    const handleChangeType = (value) => {
        if (!isEmpty(value)) {
            setType(value.value);    
            return;
        } 
        setType("");
        tagsSelect.current.select.select.clearValue();
    };

    const handleChangeTags = (values) => {
        if (!isEmpty(values)) {
            // explode string by comma here
            setTags(values.map(({value}) => value));
            return;
        }
        setTags([]);
    };

    useEffect(() => {
        if (defaultValueType !== type || defaultValueTags !== tags ) {
            onChange({type: tags.length > 0 ? type : null, tags: [...tags]});
        }

        if (!isEmpty(type)) {
            setIsDisable(false);
        } else {
            setIsDisable(true);
        }

        // eslint-disable-next-line
    }, [type, tags]);
    

    const defaultType = useCallback(() => findDtagsFilterTypeValue(defaultValueType), [defaultValueType]);
    const tagsValues = mappingTagValues();
    return (
        <Field title="Dynamic Tags" >
            <div className="D(f) W(100%) Fld(c) Fld(r)--md">
                <Select 
                    menuPlacement="top"
                    placeholder="Select Type..."
                    className="Pend(10px)--md W(30%)--md W(100%) Mb(10px)" 
                    options={optionsDynamicTagsType}
                    onChange={handleChangeType}
                    defaultValue={defaultType()}
                />
                <CreatableSelect
                    ref={tagsSelect}
                    className="W(70%)--md W(100%) Mb(10px)"
                    isMulti
                    defaultOptions
                    isDisabled={isDisabled}
                    placeholder="Create Tags..."
                    menuPlacement="top"
                    onChange={handleChangeTags}
                    defaultValue={tagsValues}
                    noOptionsMessage={() => "Type any key to create new tag"}
                />
            </div>
        </Field>
    ); 
};

export default DynamicTagsFilterField;
