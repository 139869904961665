import React, { Component } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import { uniqueId } from "lodash";

export default class Radio extends Component {
    static propType = {
        inputRef: PropTypes.func
    };

    static contextTypes = {
        $formGroup: PropTypes.object
    };

    componentDidMount() {
        this.initChecked();
    }

    componentDidUpdate() {
        this.initChecked();
    }

    initChecked() {
        const { checked } = this.props;
        this.input.checked = checked;
    }

    render() {
        const {
            children,
            id = uniqueId(),
            name,
            value,
            className,
            inputRef,
            disabled,
            onChange,
            checked,
            ...props } = this.props;

        const containerCss = {
            "container-radio": true
        };
        return (
            <label className={cs(containerCss, className)} onClick={onChange} >{children}
                <input
                    {...props}
                    id={id}
                    type="radio"
                    ref={(ref) => {
                        if (typeof inputRef === "function") {
                            inputRef(ref);
                        }
                        this.input = ref;
                    }
                    }
                    name={name}
                    disabled={disabled}

                />
                <span className="checkmark" />
            </label>
        );
    }
}
