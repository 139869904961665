import { Map } from "immutable";
import {
    APP_READY,
    ADD_BUTTON
} from "../actions";

export default function(state = {}, action) {
    switch (action.type) {
    case APP_READY:
        return Map(action.app.action.buttons);
    case ADD_BUTTON:
        const {
            key,
            ob,
            callBack
        } = action.payload;
        action.app.action.add(key, ob, callBack);
        return Map(action.app.action.buttons);
    default:
        return state;
    }
}
