import React, { useRef, useEffect, useState } from "react";
import Field from "./Field";
import {
    ButtonsGroup,
    InputGroup,
    InputTimePicker,
    splitTimeInSecondToThreeSecment,
    secontToMoment,
    parseToSecond
} from "..";
import {
    END_AT,
    START_END_AT,
    START_AT,
    INHERITE,
    getDefaultRangeState
} from "./util";
import cs from "classnames";

// function generateOptions(length, excludedOptions) {
//     const arr = [];
//     for (let value = 0; value < length; value++) {
//         if (excludedOptions.indexOf(value) < 0) {
//             continue;
//         } else {
//             arr.push(value);
//         }
//     }
//     return arr;
// }

const buttons = [INHERITE, START_AT, END_AT, START_END_AT];

const getDisabledHours = (timeStart) => {
    if (timeStart === 0) {
        return () => [];
    }
    const [hour] = splitTimeInSecondToThreeSecment(timeStart);
    const result = [];

    for (let i = 0; i < hour; i++) {
        result.push(i);
    }

    return () => [...result];
};
const getDisabledMinutes = (timeStart) => {
    if (timeStart === 0) {
        return () => [];
    }

    const [hour, minute] = splitTimeInSecondToThreeSecment(timeStart);
    const result = [];
    for (let i = 0; i < minute; i++) {
        result.push(i);
    }
    return (selectedHour) => {
        if (hour < selectedHour) {
            return [];
        }
        return [...result];
    };
};
const getDisabledSeconds = (timeStart) => {
    if (timeStart === 0) {
        return () => [];
    }

    const [hour, minute, second] = splitTimeInSecondToThreeSecment(timeStart);
    const result = [];

    for (let i = 0; i <= second; i++) {
        result.push(i);
    }

    return (selectedHour, selectedMinute) => {
        if (hour < selectedHour || minute < selectedMinute) {
            return [];
        }
        return [...result];
    };
};

// const getDisabledSecondsFrom = (h, m) => {
//     if (h === 23 && m === 59) {
//         return generateOptions(60, [59]);
//     }
// };
const getDisabledSecondsFromRange = (timeEnd) => {
    if (timeEnd === 86399) {
        return () => [];
    }

    const [hour, minute, second] = splitTimeInSecondToThreeSecment(timeEnd);
    const result = [];

    for (let i = 59; i >= second; i--) {
        result.push(i);
    }

    return (selectedHour, selectedMinute) => {
        if (hour > selectedHour || minute > selectedMinute) {
            return [];
        }
        return [...result];
    };
};
const getDisabledMinutesFromRange = (timeEnd) => {
    if (timeEnd === 86399) {
        return () => [];
    }

    const [hour, minute] = splitTimeInSecondToThreeSecment(timeEnd);
    const result = [];
    for (let i = 59; i > minute; i--) {
        result.push(i);
    }
    return (selectedHour) => {
        if (hour > selectedHour) {
            return [];
        }
        return [...result];
    };
};
const getDisabledHoursFromRange = (timeEnd) => {
    if (timeEnd === 86399) {
        return () => [];
    }
    const [hour] = splitTimeInSecondToThreeSecment(timeEnd);
    const result = [];

    for (let i = 23; i > hour; i--) {
        result.push(i);
    }

    return () => [...result];
};

const TimeRangeField = (props) => {
    const { id, from, to, onChange } = props;

    const [button, setButton] = useState(getDefaultRangeState(from, to));
    const isFirstRun = useRef(true);

    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (from === null && to === null) {
            setButton(INHERITE);
        } else if (from !== null && to === null) {
            setButton(START_AT);
        } else if (from === null && to !== null) {
            setButton(END_AT);
        } else {
            setButton(START_END_AT);
        }
    // eslint-disable-next-line
  }, []);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        if (button === INHERITE) {
            onChange(null, null);
        } else if (button === START_AT) {
            onChange(0, null);
        } else if (button === END_AT) {
            onChange(null, 86399);
        } else {
            onChange(0, 86399);
        }

    // eslint-disable-next-line
  }, [button]);

    const onFromChange = (value) => {
        let newFrom = value === null ? 0 : parseToSecond(value);
        let newTo = to;

        if (newFrom >= newTo && button === START_END_AT) {
            setErrorMessage(
                "Value Start-Time changed by system to 00:00:00, value should be less than End-Time."
            );
            newFrom = 0;
        } else {
            setErrorMessage("");
        }

        return onChange(newFrom, newTo);
    };

    const onToChange = (value) => {
        let newFrom = from;
        let newTo = value === null ? 86399 : parseToSecond(value);

        if (newFrom >= newTo && button === START_END_AT) {
            setErrorMessage(
                "Value End-Time changed by system to 23:59:59, value should be greater than Start-Time."
            );
            newTo = 86399;
        } else {
            setErrorMessage("");
        }

        onChange(from, newTo);
    };

    const onChangeButtonsGroup = (value) => {
        setButton(value);
        setErrorMessage("");
    };

    const errorMessageStyleCondition = {
        "D(n)": errorMessage === "",
        "D(b)": errorMessage !== ""
    };

    const errorMessageStyle = [
        "C(#f44336)",
        "Mt(-7px)",
        "Mb(16px)",
        "Fz(0.85rem)"
    ];

    return (
        <>
            <Field title="Time">
                <div className="D(f) Fld(c) Fld(r)--md">
                    <ButtonsGroup
                        list={buttons}
                        type={button}
                        onChange={onChangeButtonsGroup}
                    />

                    {button !== INHERITE && (
                        <InputGroup className="Mstart(8px)--md Mt(8px) Mt(0)--md Mb(0)! W(a)">
                            {button !== END_AT && (
                                <InputTimePicker
                                    id={id + "-from-time-picker"}
                                    className={button === START_END_AT ? "Fz(1rem) W(90px) from-time-picker Bdrs(radiusLeft)!" : "Fz(1rem) W(90px) from-time-picker Bdrs(radius)!"}
                                    value={secontToMoment(from)}
                                    onChange={onFromChange}
                                    disabledHours={
                                        button === START_END_AT
                                            ? getDisabledHoursFromRange(to)
                                            : function() {
                                                return [];
                                            }
                                    }
                                    disabledMinutes={
                                        button === START_END_AT
                                            ? getDisabledMinutesFromRange(to)
                                            : function() {
                                                return [];
                                            }
                                    }
                                    disabledSeconds={
                                        button === START_END_AT
                                            ? getDisabledSecondsFromRange(to)
                                            : function() {
                                                return [];
                                            }
                                    }
                                />
                            )}
                            {button === START_END_AT && (
                                <InputGroup.Addon>To</InputGroup.Addon>
                            )}
                            {button !== START_AT && (
                                <InputTimePicker
                                    id={id + "-to-time-picker"}
                                    className={button === START_END_AT ? "Fz(1rem) W(90px) from-time-picker Bdrs(radiusRight)!" : "Fz(1rem) W(90px) from-time-picker Bdrs(radius)!"}
                                    value={secontToMoment(to)}
                                    disabledHours={
                                        button === START_END_AT
                                            ? getDisabledHours(from)
                                            : function() {
                                                return [];
                                            }
                                    }
                                    disabledMinutes={getDisabledMinutes(from)}
                                    disabledSeconds={
                                        button === START_END_AT
                                            ? getDisabledSeconds(from)
                                            : function() {
                                                return [];
                                            }
                                    }
                                    onChange={onToChange}
                                />
                            )}
                        </InputGroup>
                    )}
                </div>
            </Field>
            <p className={cs(errorMessageStyle, errorMessageStyleCondition)}>
                {errorMessage}
            </p>
        </>
    );
};

export default TimeRangeField;
