import React, { Component } from "react";
import cs from "classnames";
import ReactLoading from "react-loading";

export default class Loading extends Component {
    render() {
        const className = [
            "Bgc(#dddddd)",
            "C(#fff)",
            "D(f)",
            "Jc(sb)",
            "Lh(60px)",
            "Pos(r)"
        ];

        return (
            <div className="W(100%) H(100%) Mih(100vh)">
                <header>
                    <div className={cs(className)}>
                        <div className="W(60px) Z(1) Bgc(#000.1) D(f) Jc(c) Ai(c)">
                            <div className="W(20px) H(20px) Bgc(#efefef)" />
                        </div>
                        <h1 className="M(0) Z(1) Px(20px) Fz(20px) Fw(b) Flxg(2) D(f) Ell">
                            <span className="D(n) D(ib)--md">Lumio App</span>
                        </h1>

                        <div className="Fl(end) D(f) Jc(c) Ai(c)">
                            <div className="W(60px) H(60px) BdStart Bdc(#fff) D(f) Jc(c) Ai(c)">
                                <div className="W(20px) H(20px) Bgc(#efefef)" />
                            </div>
                            <div className="W(60px) H(60px) BdStart Bdc(#fff) D(f) Jc(c) Ai(c)">
                                <div className="W(20px) H(20px) Bgc(#efefef)" />
                            </div>
                            <div className="W(150px) H(60px) BdStart Bdc(#fff) D(f) Jc(c) Ai(c)">
                                <div className="W(110px) H(20px) Bgc(#efefef)" />
                            </div>
                        </div>
                    </div>
                </header>

                <div className="W(100%) D(f) Jc(c) Ai(c) Pos(a) T(50%) TranslateY(-50%)">
                    <ReactLoading type="spin" color="#dddddd" height={60} width={60} />
                </div>
            </div>
        );
    }
}
