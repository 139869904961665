import React from "react";

import { Content, BaseModalComponent } from "../modal";

const isDev = process.env.NODE_ENV !== "production";

/**
 * Component modal for renew JWT for authentication via backedn
 */
export default class AuthRequest extends BaseModalComponent {
    constructor(props) {
        super(props);

        this.container = null;
        this.interval = null;
    }

    onClose = () => {
        return true;
    };

    /**
     *
     */
    componentDidMount() {
        const frameRef = this.container.childNodes[0];
        this.interval = setInterval(() => {
            try {
                const frameDocument = frameRef.contentWindow ? frameRef.contentWindow.document : frameRef.contentDocument;
                if (frameDocument.body.innerHTML === "OK" || frameDocument.title === "OK") {
                    const { actions } = this.context.$lumioApp;
                    clearInterval(this.interval);
                    actions.reloadApp(this.props.configs);
                    actions.closeModal();
                }
            } catch (e) {
                console.log(e);
            }
        }, 1000);
    }

    render() {
        const iframe = {
            __html: "<iframe src=\"" + (isDev ? "http://localhost:3001" : "") + "/token/request\" width=\"100%\" height=\"100%\"></iframe>"
        };
        return (
            <Content >
                <div className="H(100%) Bgc(#fff)" dangerouslySetInnerHTML={iframe} ref={(r) => { this.container = r; }}/>
            </Content>
        );
    }
}
