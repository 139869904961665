import React, { Component } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import { upperFirst } from "lodash";
import Ink from "react-ink";

import Hamburger from "./Hamburger";
import { Menu, MenuItem, Dropdown } from "../";

export default class Actions extends Component {
    componentDidUpdate() {
        window.addEventListener("keydown", this.handleSaveKey);
    }

    componentWillUnmont() {
        window.removeEventListener("keydown", this.handleSaveKey);
    }

    handleSaveKey = (event) => {
        const { buttons, isSaving, orderMode, isChanges } = this.props;
        if (orderMode || isSaving) {
            event.preventDefault();
            return false;
        }
        const save = buttons.get("save");
        if ((event.ctrlKey || event.metaKey) && event.which === 83) {
            if (!isSaving && isChanges) {
                save.trigger();
            }
            event.preventDefault();
            return false;
        }
    };
    render() {
        const {
            buttons,
            isSaving,
            orderMode,
            isChanges,
            beforeSaveData,
            ...props
        } = this.props;
        return (
            <div {...props}>
                <div className="Z(1) D(f)">
                    {this.mappingButtons().map((button, index) => (
                        <ActionButton
                            key={index}
                            onClick={this.handleClickMenu(button.trigger)}
                        >
                            {button.name}
                        </ActionButton>
                    ))}
                </div>
                {this.renderDropdownMenu()}
                {this.renderHamburgerMenu()}
            </div>
        );
    }

    renderDropdownMenu() {
        const { buttons, orderMode } = this.props;

        const linkCss = [
            "C(color) Td(n) D(b)",
            "Px(20px)",
            "Bgc(primary-50):h",
            "Pos(r)"
        ];
        const save = buttons.get("save");
        const exit = buttons.get("exit");
        return (
            <Dropdown
                className="Pend(18px)--md D(n) D(b)--md Fz(0.9em) BdStart Bdc(#fff)"
                toggleCss="M(0)"
                label={this.renderLabel()}
                disabled={orderMode}
                ref={(ref) => (this.dropdown = ref)}
            >
                <Menu className="Bgc(#fff) Ta(s) Miw(150px)">
                    {!save || (
                        <MenuItem
                            linkClassName={cs(linkCss)}
                            onClick={this.handleClickMenu(save.trigger)}
                        >
                            <Ink />
                            {upperFirst(save.name)}
                        </MenuItem>
                    )}
                    {!exit || (
                        <MenuItem
                            linkClassName={cs(linkCss)}
                            onClick={this.handleClickMenu(exit.trigger)}
                        >
                            <Ink />
                            {upperFirst(exit.name)}
                        </MenuItem>
                    )}
                </Menu>
            </Dropdown>
        );
    }

    renderHamburgerMenu() {
        const { orderMode } = this.props;
        const linkCss = [
            "C(color) Td(n) D(b)",
            "Px(20px)",
            "Bgc(#000.1):h",
            "Pos(r)"
        ];

        const css = {
            "D(n)--md": true
        };
        return (
            <Hamburger
                className={cs(css)}
                disabled={orderMode}
                ref={(ref) => (this.hamburger = ref)}
            >
                <Menu className="Bgc(#fff) Miw(150px)">
                    {this.mappingButtons(false).map((button, index) => (
                        <MenuItem
                            key={index}
                            linkClassName={cs(linkCss)}
                            onClick={this.handleClickMenu(button.trigger)}
                        >
                            <Ink />
                            {button.name.toUpperCase()}
                        </MenuItem>
                    ))}
                </Menu>
            </Hamburger>
        );
    }

    mappingButtons(isCustom = true) {
        const { buttons } = this.props;
        const mappingButtons = [];

        for (const button of buttons) {
            if (button[1].isCustom === isCustom) {
                mappingButtons.push(button[1]);
            }
        }

        return mappingButtons;
    }

    handleClickMenu(trigger) {
        const { isSaving, orderMode, beforeSaveData } = this.props;
        return (e) => {
            e.preventDefault();
            if (isSaving || orderMode) {
                return;
            }
            this.dropdown.hide();
            this.hamburger.hide();

            if (typeof beforeSaveData === "function") {
                if (beforeSaveData()) {
                    trigger();
                }
            } else {
                trigger();
            }
        };
    }

    renderLabel() {
        const { buttons, orderMode, beforeSaveData } = this.props;
        const saveAndExit = buttons.get("saveAndExit");

        return (
            <a
                className="Pstart(20px)--md Pend(8px)--md C(#fff) Td(n) D(ib) Pos(r)"
                onClick={(e) => {
                    e.preventDefault();
                    if (orderMode) {
                        return;
                    }

                    if (typeof beforeSaveData === "function") {
                        if (beforeSaveData()) {
                            saveAndExit.trigger();
                        }
                    } else {
                        saveAndExit.trigger();
                    }
                }}
                href="#save"
            >
                <Ink />
                {saveAndExit.name.toUpperCase()}
            </a>
        );
    }
}

Actions.propTypes = {
    buttons: PropTypes.object.isRequired,
    isSaving: PropTypes.bool,
    isChanges: PropTypes.bool,
    orderMode: PropTypes.bool
};

const ActionButton = ({ onClick, children }) => {
    return (
    // eslint-disable-next-line
    <a
            className="Px(15px) Px(20px)--md C(#fff) BdStart Bdc(#fff) D(ib) Pos(r) Cur(p)"
            onClick={onClick}
        >
            <Ink />
            {children}
        </a>
    );
};
