import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { createStore, applyMiddleware } from "redux";
import createReducer from "./reducers";

const loggerMiddleware = createLogger();

const middleware = [thunkMiddleware];

if (process.env.NODE_ENV !== "production") {
    middleware.push(loggerMiddleware);
}

export default function configureStore(initialState) {
    const store = createStore(
        createReducer(),
        initialState,
        applyMiddleware(...middleware)
    );
    store.asyncReducers = {};
    return store;
}

export function injectAsyncReducer(store, name, asyncReducer) {
    if (store.asyncReducers && store.asyncReducers[name] !== undefined) {
        return;
    }
    store.asyncReducers[name] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
}
