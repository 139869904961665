import React, { Component } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "./css/timepicker.css";

import { childCss as inputGroupCss } from "./InputGroup";
import { childCss as formControlCss } from "./FormControl";

export default class InputTimePicker extends Component {
    handleOnChange = (value) => {
        const { onChange, format } = this.props;

        if (typeof onChange === "function") {
            if (value) {
                onChange(value.format(format));
            } else {
                onChange("");
            }
        }
    };

    static propsTypes = {
        onChange: PropTypes.func.isRequired
    };

    static defaultProps = {
        // readOnly: true,
        format: "HH:mm:ss",
        placeholder: "Set time",
        onChange: (value) => {
            console.log(value);
        }
    };

    render() {
        const {
            // readOnly,
            format,
            name,
            className,
            placeholder,
            onChange,
            defaultValue,
            ...props
        } = this.props;

        const css = [
            ...formControlCss,
            ...inputGroupCss,

            "C(color)",
            "Bgi(n)",
            "Bgcp(pb)",
            "Bd",
            "Bdc(border-color-2)",
            "Bdrs(radius)",
            "D(b)",
            "W(88px)",

            "O(n):f",
            "Trs(inputFocuseTrs)",
            "Bdc(inputBorderColorHover):f",
            "Bxsh(inputBoxShadowHover):f",
            "C(placeholderColor)::ph",

            "Cur(p)"
        ];

        const styleDetailLabel = [
            "Pos(a)",
            "T(-20px)",
            "C(l-neutral-300)",
            "Fz(14px)",
            "Pstart(2px)"
        ];

        return (
            <div className="Pos(r)">
                <span className={cs(styleDetailLabel)}>HH:mm:ss</span>
                <TimePicker
                    className={cs(css, className)}
                    defaultValue={defaultValue ? moment(defaultValue, format) : null}
                    onChange={this.handleOnChange}
                    {...props}
                />
            </div>
        );
    }
}
