import React from "react";

import cs from "classnames";
import { isEqual } from "lodash";

import { parseToSecond } from "./utility";
import { Header, Content, Footter, BaseModalComponent } from "./modal";
import { TextArea } from "./form";
import { Button, Icon } from "./";
import { DurationField, Field } from "./clip";

const INHERIT = "Inherit";
const CUSTOME_DURATION = "Custom";

export default class SettingsModal extends BaseModalComponent {
    constructor(props) {
        super(props);
        const { data } = props;
        this.container = null;
        this.state = {
            description: data.description || "",
            duration: data.duration || 0,
            durationButton: data.duration > 0 ? CUSTOME_DURATION : INHERIT
        };
    }


    updateDuration = (value) => {
        const { actions, data } = this.props;
        if (!isEqual(data.duration, value)) {
            actions.updateDuration(value);
        }
    };

    updateDescription = (value) => {
        const { actions, data } = this.props;
        if (!isEqual(data.description, value)) {
            actions.updateDescription(value);
        }
    };

    handleOnChangeDuration = (value) => {
        const duration = parseToSecond(value);
        this.setState({ duration });
    };

    onClickSave = (e) => {
        const { duration, description } = this.state;
        const { afterSave = () => {} } = this.props;
        this.updateDuration(duration);
        this.updateDescription(description);
        afterSave();
        this.handleClose(e);
    };

    handleChangeDescription = (e) => {
        const description = e.target.value;
        this.setState({ description });
    };

    render() {
        const {
            duration = 0,
            description = ""
        } = this.state;
        const { className, children } = this.props;
        return (
            <div className={cs(className, "Bgc(#ffffff)")} ref ={(ref) => this.container = ref}>
                <Content>
                    <Header close={this.handleClose}><Icon icon="settings"/> Setting</Header>
                    <div className="P(16px)">
                        <div className="Mb(16px)">
                            <DurationField 
                                duration={duration}
                                onChange={this.handleOnChangeDuration}
                                getPopupContainer={() => this.container}
                                durationInputRef ={(ref) => {this.duration = ref;}}
                            />
                        </div>
                        
                        <div className="Mb(16px)">
                            <Field title="Description">
                                <TextArea
                                    value={description} // innerHTML of the editable div
                                    onChange={this.handleChangeDescription} // handle innerHTML change
                                />
                            </Field>
                        </div>
                        {children}
                    </div>
                    <Footter className="D(f) Jc(fe) Py(12px) Px(16px) Bdtw(1px) Bdbw(0px) Bdstartw(0px) Bdendw(0px) Bds(s) Bdc(border-color)">
                        <Button onClick={this.onClickSave} color="primary">Save</Button>
                    </Footter>
                </Content>
            </div>
        );
    }
}
