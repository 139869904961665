import React, { Component } from "react";
import cs from "classnames";

export { default as MenuItem } from "./MenuItem";
export { default as Devider } from "./Devider";

export default class Menu extends Component {
    render() {
        const { items, className, ...props } = this.props;
        const menuCss = [
            "Menu",
            "P(0)",
            "M(0)",
            "List(n)"
        ];
        return (
            <ul className={cs(menuCss, className)} {...props} />
        );
    }
}
