import React, { Component } from "react";
import Icon from "../Icon";
import cs from "classnames";

export default class Header extends Component {
    render() {
        const {
            className,
            children,
            exit = true,
            close = () => {},
            ...props
        } = this.props;
        return (
            <div className={cs("modal-header Fz(20px) Py(20px) Px(16px) D(f) Ai(c) Bdtw(0px) Bdbw(1px) Bdstartw(0px) Bdendw(0px) Bds(s) Bdc(border-color)", className)} {...props}>
                <div className="Flxg(1) Ell">
                    {children}
                </div>
                {exit ? <Icon icon="close" className="Cur(p)" onClick={close} /> : ""}
            </div>
        );
    }
}
