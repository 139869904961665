import {DEVICE_TAGS_LOADED, DEVICE_TAGS_FETCHING} from "../actions";


export default function(state = {data: [], loaded: false, fetching: false}, action) {
    switch (action.type) {
    case DEVICE_TAGS_LOADED:
        const data = action.data;
        return {loaded: true, data: [...data], fetching: false };
    case DEVICE_TAGS_FETCHING:
        return {...state, fetching: true};
    default:
        return state;
    }
}
