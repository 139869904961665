import React from "react";
import cs from "classnames";
import PropTypes from "prop-types";
import CheckBox from "./CheckBox";

const CheckBoxGroup = ({ data, onChange = () => {}, disabled = false }) => {
    const handleOnChange = (option) => {
        return (e) => {
            e.preventDefault();
            onChange(option);
        };
    };

    return data.map((option) => {
        const checkBoxProps = {
            key: option.value,
            label: option.label,
            value: option.value,
            defaultChecked: !!option.checked,
            disabled: disabled,
            className: cs(option.className),
            containerCheckBoxCss: cs(option.containerCheckBoxCss),
            onChange: handleOnChange(option)
        };
        return <CheckBox {...checkBoxProps}>{option.label}</CheckBox>;
    });
};

CheckBoxGroup.propsTypes = {
    onChange: PropTypes.func,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.any.isRequired,
            checked: PropTypes.bool.isRequired
        })
    ).isRequired,
    inlne: PropTypes.bool,
    disabled: PropTypes.bool
};

export default CheckBoxGroup;
