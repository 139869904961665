import React, {
    Component
} from "react";
import PropTypes from "prop-types";
import {
    connect
} from "react-redux";

import {
    Header,
    Loading,
    ErrorBoundary,
    Modal,
    Toast,
    AuthRequest,
    Card,
    Icon,
    BlockLoading
} from "./components";

import { TYPE } from "./components/toast";
import { parseUrl } from "./components/utility";
import Container from "./container";
import ApiActions from "./ApiActions";
import { LumioApp } from "./LumioContex";

class AsyncApp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            moduleLoaded: false,
            configs: {}
        };

        this.app = null;

        this.actions = ApiActions(this);

        window.onbeforeunload = this.handleBeforeCloseWindow;
    }

    /**
     * Handle window/tab close
     *
     * @param {*} evt
     */
    handleBeforeCloseWindow = (evt) =>{
        const { sdk: { app } } = this.props;

        if (typeof app.isChanges !== "undefined") {
            if (app && app.isChanges) {
                const message = "Are you sure ?";
                evt.returnValue = message;
                return message;
            }
        }

        return null;
    };

    /**
     *
     *
     * @param {*} prevProps
     */
    componentDidUpdate(prevProps) {
        const { exitRequested } = this.props;
        if (exitRequested) {
            if (window.opener) {
                window.opener.focus();
                window.close();
            } else {
                setTimeout(() => {
                    this.actions.toast("Please close the tab manually.", null, { type: TYPE.WARNING });
                }, 1000);
            }
        }
        this.renewAuth(prevProps);
    }

    componentDidMount() {
        const configs = parseUrl(window.location.pathname);
        configs.baseUrl = "/api";
        configs.toast = this.toast;
        this.setState({ configs });
        this.actions.load(configs);
    }

    loadApp = (app, SettingsPage) => {
        this.app = app;
        this.setState({ moduleLoaded: true }, () => {
            // add default button for settings
            this.actions.addActionButton("settings", { name: <Icon icon="settings" /> }, this.actions.openSettingsModal(SettingsPage));
        });
    };

    renewAuth = (prevProps) => {
        const { isRenewingAuth } = this.props;
        if (prevProps.isRenewingAuth === false && isRenewingAuth) {
            setTimeout(() => {
                this.actions.openModal(<AuthRequest configs={this.state.configs}/>);
            }, 1000);
        } else if (prevProps.isRenewingAuth === true && isRenewingAuth === false) {
            setTimeout(() => {
                this.actions.closeModal();
            }, 1000);
        }
    };

    getChildContext() {
        return this.getContext();
    }

    getContext = () => {
        const { data, fileGrid, orderMode, globalState } = this.props;
        return {
            $lumioApp: {
                actions: this.actions,
                data,
                fileGrid,
                orderMode,
                globalState
            }
        };
    };

    render() {
        const {
            isFetching,
            data,
            buttons,
            isSaving,
            modal,
            sdk,
            orderMode,
            isChanges
        } = this.props;
        const {
            name,
            apps_type,
            icon,
            color
        } = data;

        const { moduleLoaded, configs } = this.state;
        const headerProps = {
            name,
            icon,
            color,
            buttons,
            appsType: apps_type,
            actions: this.actions,
            orderMode,
            isSaving,
            isChanges,
            sdk
        };

        const hasConfigs = configs.apps_type || configs.id;
        if (!hasConfigs) {
            const { backendBasePath = "http://lumio-backend.localdev" } = window.$configs;
            return (
                <div className="StretchedBox">
                    <div className="W(con)--lg Mx(a) H(100%) D(f) Ai(c) Ac(c) Jc(c)">
                        <Card> You probably want to access <a href={backendBasePath}>Backend</a> </Card>
                    </div>
                </div>
            );
        }
        return (
            <ErrorBoundary>
                <LumioApp lumioApp={this.getContext()}>
                    <Modal actions={this.actions} sdk={sdk} {...modal}/>
                    <Toast/>
                    {isFetching || !moduleLoaded ? <Loading/> : 
                        <>
                            <Header {...headerProps}/>
                            <Container component={this.app} />
                        </>
                    }

                    { isSaving &&
                        <BlockLoading >
                            Saving...
                        </BlockLoading>
                    }

                    {orderMode &&
                        <div className="Pos(f) T(0) B(0) Start(0) End(0) Bg(#000.5) Z(900)"/>
                    }
                </LumioApp> 
            </ErrorBoundary>
        );
    }
}

AsyncApp.propTypes = {
    exitRequested: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isChanges: PropTypes.bool.isRequired,
    isRenewingAuth: PropTypes.bool.isRequired,
    orderMode: PropTypes.bool,
    isSaving: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    buttons: PropTypes.object,
    error: PropTypes.objectOf(Error),
    modal: PropTypes.any,
    data: PropTypes.object.isRequired,
    sdk: PropTypes.object
};

AsyncApp.defaultProps = {
    orderMode: false
};

AsyncApp.childContextTypes = {
    $lumioApp: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    const {
        sdk
    } = state;
    const {
        renewAuth: isRenewingAuth,
        exitRequested,
        didInvalidate,
        isFetching,
        orderMode,
        isSaving,
        error,
        modal,
        app
    } = sdk || {
        error: null,
        modal: null,
        isSaving: false,
        orderMode: false,
        isFetching: true,
        exitRequested: false,
        didInvalidate: false,
        isRenewingAuth: false
    };

    return {
        ...state,
        globalState: state,
        isChanges: !!(app && app.isChanges),
        error,
        modal,
        isSaving,
        isRenewingAuth,
        orderMode,
        isFetching,
        didInvalidate,
        exitRequested
    };
}

export default connect(mapStateToProps)(AsyncApp);
