import React, { Component } from "react";
import PropTypes from "prop-types";
import cs from "classnames";

export default class Message extends Component {
    static contextTypes = {
        $formGroup: PropTypes.object
    };

    render() {
        const { className, children, ...props } = this.props;
        const { $formGroup: { message } } = this.context;

        const css = [
            "form-control--success_C(success)",
            "form-control--warning_C(warning)",
            "form-control--error_C(error)",
            "Mb(inputMb)",
            "Mt(-8px)",
            "Px(4px)",
            "C(l-neutral-500)",
            "Fz(0.875rem)"
        ];
        let renderChildren = children;

        if (message) {
            renderChildren = message;
        }

        return (
            <div className={cs(css, className)} {...props}> {renderChildren}</div>
        );
    }
}
