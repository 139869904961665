import React, { Component } from "react";
import cs from "classnames";

export default class InputGroupButton extends Component {
    render() {
        const {
            className,
            children,
            onClick = () => {
                console.log("clicked");
            },
            type = "button",
            buttonClassName,
            ...props
        } = this.props;
        const css = [
            "form-input-group-button",
            "Pos(r)",
            "Whs(nw)",
            "Ai(st)",
            "D(f)",
            "Bd",
            "Bdc(inputBorderColor)",
            "Bdendw(0)",
            "Bdstartw(0)",

            "Bdendw(1px):lc",
            "Bdstartw(1px):fc",
            "Bdrs(radiusRight):lc",
            "Bdrs(radiusLeft):fc"
        ];

        return (
            <div className={cs(css, className)} {...props}>
                <button
                    className={cs(
                        "Bdw(0) O(n):f Bgc(#fafafa) Cur(p) C(color)",
                        buttonClassName
                    )}
                    type={type}
                    onClick={onClick}
                >
                    {children}
                </button>
            </div>
        );
    }
}
