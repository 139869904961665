import React, { Component } from "react";
import cs from "classnames";

export default class InputGroupAddon extends Component {
    render() {
        const { className, ...props } = this.props;
        const css = [
            "Px(12px)",
            "Py(6px)",
            "Mb(0)",
            "Fw(400)",
            "C(color)",
            "Ta(c)",
            "Bgc(l-neutral-100)",
            "Bd",
            "Bdc(border-color-2)",

            "Bdendw(0)",
            "form-control+Bdstartw(0)",

            "Bdendw(1px):lc",
            "Bdrsbstart(radius):fc",
            "Bdrsbend(radius):lc",
            "Bdstartw(1px):fc",

            "Bdrs(radiusLeft):fc",
            "Bdrs(radiusRight):lc",

            "D(f)",
            "Ai(c)"
        ];
        return <div className={cs(css, className)} {...props} />;
    }
}
