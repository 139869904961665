import React, { Component } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
// import {Feedback} from './Feedback';

export default class FormGroup extends Component {
    getChildContext() {
        const { controlId, validationState, message } = this.props;

        return {
            $formGroup: {
                controlId,
                validationState,
                message
            }
        };
    }

    // hasFeedBack() {
    //     const {children} = this.props;
    //     let hasFeedBack = false;
    //     React.Children.map(children, child => {
    //         if (child.type === Feedback) {
    //             hasFeedBack = true;
    //         }
    //     });

    //     return hasFeedBack;
    // }

    render() {
        const { validationState, validation, className, controlId, ...props } = this.props;
        const success = validationState === "success";
        const warning = validationState === "warning";
        const error = validationState === "error";
        const css = {
            "form-control": true,
            // 'form-control--feedback': this.hasFeedBack(),

            "form-control--success": success,
            "form-control--warning": warning,
            "form-control--error": error,

            "C(success)": success,
            "C(warning)": warning,
            "C(error)": error
        };

        return (
            <div className={cs(css, className)} {...props} />
        );
    }
}

FormGroup.propTypes = {
    controlId: PropTypes.string,
    validationState: PropTypes.oneOf([
        "success", "warning", "error", null
    ]),
    message: PropTypes.any
};

FormGroup.childContextTypes = {
    $formGroup: PropTypes.object.isRequired
};
