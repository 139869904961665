import React, { Component } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import { injectAsyncReducer } from "../store";

export default class Container extends Component {
    static contextTypes = {
        $lumioApp: PropTypes.object
    };

    render() {
        const { globalState, ...lumioApp } = this.context.$lumioApp;
        const { className, component: App, ...props } = this.props;

        const appProps = { ...lumioApp, ...globalState, injectAsyncReducer };
        return (
            <div className="Pt(64px)">
                <div className={cs("Pos(r)", className)} {...props}>
                    <App {...appProps} />
                </div>
            </div>
        );
    }
}
