import React, { Component } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import { upperFirst } from "lodash";
import { Tooltip } from "react-tippy";

import "react-tippy/dist/tippy.css";

import { Icon } from "../";
import Actions from "./Actions";

export default class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            onEditName: false,
            name: props.name || "Untitle"
        };

        this.handleChange = this.handleChange.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.handleEnterKeypress = this.handleEnterKeypress.bind(this);
    }

    handleChange(e) {
        e.preventDefault();
        const { name } = this.state;
        const newName = e.target.value;

        if (newName.length <= 50 && name !== newName) {
            const { actions } = this.props;
            this.setState({ name: newName }, () => {
                actions.updateName(newName);
            });
        }
    }

    render() {
        const {
            appsType,
            icon = false,
            color,
            buttons,
            isSaving,
            orderMode,
            isChanges
        } = this.props;
        const {
            sdk: {
                app: { beforeSaveData = null }
            }
        } = this.props;

        const className = [
            "Bgc(#ccc)",
            "C(#fff)",
            "D(f)",
            "Jc(sb)",
            "Lh(60px)",
            "Pos(r)",
            "Bxsh(deep1)",
            "Mih(64px)"
        ];
        const style = {
            background: color
        };

        const headerClassCondition = {};

        const { name, onEditName } = this.state;
        const title = appsType === "template" ? "Loop" : upperFirst(appsType);

        // custom header icon
        let headerCustomIcon = "";
        switch (appsType) {
        case "pooh":
            headerCustomIcon = "lumio-icon-" + appsType + "_clip";
            break;
        default:
            break;
        }

        return (
            <header
                className={cs(
                    "Pos(f) Start(0) End(0) Fz(1.25em) Z(898)",
                    headerClassCondition
                )}
            >
                <div className={cs(className)} style={style}>
                    <Tooltip
                        title={upperFirst(title)}
                        position="bottom"
                        trigger="mouseenter"
                    >
                        <div className="W(60px) Z(1) Fz(1.6em) Bgc(#000.1) Ta(c) Cur(p)">
                            {headerCustomIcon !== "" ? <Icon icon={false} className={headerCustomIcon + " Lh(60px)"} /> : <Icon icon={icon || appsType} />}
                        </div>
                    </Tooltip>
                    <h1 className="M(0) Z(1) Py(12px) Px(15px) Px(20px)--md Fw(b) Flxg(2) D(f) Ell Fz(1em) Lh(36px)">
                        {onEditName ? (
                            <input
                                className="Bd(n) O(n) Bgc(t) C(#fff) P(0) M(0) Ff(roboto) Flxg(2) Bdbs(s) Bdbw(2px) Bdbc(white) Fs(i)"
                                ref={(ref) => {
                                    this.inputName = ref;
                                }}
                                autoFocus
                                value={name}
                                onKeyPressCapture={this.handleEnterKeypress}
                                onChange={this.handleChange}
                                onBlur={this.toggleEdit}
                                onFocus={this.moveCaretAtEnd}
                            />
                        ) : (
                            <span
                                onClick={this.toggleEdit}
                                className="Fw(n) Ell W(100%) Bdbs(s) Bdbw(2px) Bdbc(t) Bdbc(white):h"
                            >
                                {name}
                            </span>
                        )}
                    </h1>
                    <Actions
                        className="Flxb(a) D(f)"
                        buttons={buttons}
                        isSaving={isSaving}
                        orderMode={orderMode}
                        isChanges={isChanges}
                        beforeSaveData={beforeSaveData}
                    />
                </div>
            </header>
        );
    }

    handleEnterKeypress(e) {
        if (e.key === "Enter") {
            e.preventDefault();
            this.toggleEdit(e);
        }
    }

    moveCaretAtEnd(e) {
        var temp_value = e.target.value;
        e.target.value = temp_value;
    }

    toggleEdit(e) {
        const { onEditName } = this.state;
        this.setState({ onEditName: !onEditName });
        if (onEditName) {
            const { actions } = this.props;
            actions.updateName(e.target.value);
        }
    }
}

Header.propTypes = {
    buttons: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    appsType: PropTypes.string.isRequired,
    isChanges: PropTypes.bool,
    icon: PropTypes.string,
    color: PropTypes.string,
    orderMode: PropTypes.bool,
    isSaving: PropTypes.bool,
    actions: PropTypes.shape({
        addActionButton: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
        openModal: PropTypes.func.isRequired,
        updateConfigs: PropTypes.func.isRequired,
        updateDescription: PropTypes.func.isRequired,
        updateDuration: PropTypes.func.isRequired,
        updateName: PropTypes.func.isRequired
    }).isRequired
};
