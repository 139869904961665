import {
    RECEIVE_DATA,
    SAVE_DATA_SUCCEED,
    SET_NAME,
    SET_DESCRIPTION,
    SET_DURATION,
    SET_CONFIGS,
    SET_CONTENTS,
    UPDATE_DETAIL
} from "../actions";

export default function(state = {}, action) {
    switch (action.type) {
    case RECEIVE_DATA:
        const data = action.app.data;
        return { ...data
        };

    case SET_NAME:
        const name = action.name;
        return { ...state,
            name
        };

    case SET_DESCRIPTION:
        const description = action.description;
        return { ...state,
            description
        };

    case SET_DURATION:
        const duration = action.duration;
        return { ...state,
            duration
        };

    case SET_CONFIGS:
        const configs = action.configs;
        return { ...state,
            configs
        };

    case SET_CONTENTS:
        const contents = action.contents;
        return { ...state,
            contents
        };

    case SAVE_DATA_SUCCEED:
        if (!action.app) {
            return state;
        }
        const newData = action.app.data;
        return { ...newData };

    case UPDATE_DETAIL:
        const newDetail = action.detail;
        const { detail = {} } = state;
        return {
            ...state,
            detail: { ...detail, ...newDetail }
        };
    default:
        return state;
    }
}
