import React, { Component } from "react";
import { Provider } from "react-redux";
import MetaTags from "react-meta-tags";

import configureStore from "./store";
import AsyncApp from "./AsyncApp";

import "normalize.css";
import "./assets/base.scss";
import "./assets/lumio-icon.scss";
import "./assets/App.css";

const store = configureStore();

export default class Root extends Component {
    render() {
        return (
            <Provider store={store}>
                <MetaTags>
                    <meta
                        name="viewport"
                        content="user-scalable=no, width=device-width, initial-scale=1, maximum-scale=1"
                    />
                </MetaTags>
                <AsyncApp />
            </Provider>
        );
    }
}
