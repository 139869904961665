import {
    combineReducers
} from "redux";
import data from "./data";
import buttons from "./buttons";
import sdk from "./sdk";
import tags from "./tags";
import deviceTags from "./deviceTags";
import rpcLib from "./rpcLib";

export default function createReducer(asyncReducers) {
    return combineReducers({
        data,
        buttons,
        sdk,
        tags,
        deviceTags,
        rpcLib,
        ...asyncReducers
    });
}
