import React, { Component } from "react";
import PropTypes from "prop-types";
import cs from "classnames";

export default class Footter extends Component {
    static contextTypes = {
        $modalContainer: PropTypes.object
    };
    render() {
        const { className, ...props } = this.props;
        return (
            <div className={cs(className)} {...props}/>
        );
    }
}
