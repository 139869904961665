import { Component } from "react";
import PropTypes from "prop-types";

export default class BaseModalComponent extends Component {
    static contextTypes = {
        $lumioApp: PropTypes.object,
        $modalContainer: PropTypes.object
    };

    handleClose = (e) => {
        e.preventDefault();
        const { actions } = this.context.$lumioApp;
        return actions.closeModal(this.onClose);
    };

    onClose = () => {
        return true;
    };
}
