import React, { Component } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { formatDate, parseDate } from "react-day-picker/moment";

import { childCss as inputGroupCss } from "./InputGroup";
import { childCss as formControlCss } from "./FormControl";

export default class DatePicker extends Component {
    handleClearClick = () => {
        this.handleOnChange("");
    };

    handleOnChange = (value) => {
        const { onChange } = this.props;

        if (typeof onChange === "function") {
            onChange(value);
        }
    };

    static propsTypes = {
        onChange: PropTypes.func.isRequired
    };

    static defaultProps = {
        readOnly: true,
        format: "D MMMM YYYY",
        placeholder: "Pick a date",
        onChange: (value) => {
            console.log(value);
        }
    };

    render() {
        const {
            readOnly,
            format,
            name,
            className,
            placeholder,
            value
        } = this.props;

        const css = [
            ...formControlCss,
            ...inputGroupCss,

            "Px(10px)",
            "Py(14px)",
            "C(color)",
            "Bgi(n)",
            "Bgcp(pb)",
            "Bd",
            "Bdc(inputBorderColor)",
            "Bdrs(3px)",
            "D(b)",
            "W(100%)",
            "Mb(inputMb)",

            "O(n):f",
            "Trs(inputFocuseTrs)",
            "Bdc(inputBorderColorHover):f",
            "Bxsh(inputBoxShadowHover):f",
            "C(placeholderColor)::ph",

            "Cur(p)"
        ];

        return (
            <div className="Pos(r)">
                <DayPickerInput
                    format = {format}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    readOnly={readOnly}
                    name={name}
                    inputProps={{
                        className: cs(className, css),
                        readOnly: readOnly
                    }}
                    dayPickerProps={{
                        todayButton: "TODAY"
                    }}
                    placeholder = {placeholder}
                    onDayChange = {this.handleOnChange}
                    value={value ? moment(value).format(format) : value}
                />
                <div className="Pos(a) T(18px) End(15px) Fw(600) Cur(p) C(color)" onClick={this.handleClearClick}>CLEAR</div>
            </div>
        );
    }
}
