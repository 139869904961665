import React, { Component } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import { capitalize } from "lodash";
import Ink from "react-ink";

import { MenuItem, Menu } from ".";

export default class ButtonsGroup extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        type: PropTypes.string,
        list: PropTypes.array.isRequired
    };

    static defaultProps = {
        onChange: () => {}
    };

    handleClick = (value) => {
        const { onChange } = this.props;
        return () => {
            onChange(value);
        };
    };

    conditionCss = (value) => {
        const { type } = this.props;
        return {
            "buttonsGroup--selected": value === type,
            "Bgc(primary)": value === type
        };
    };

    get linkCss() {
        return [
            "D(ib)",
            "C(inherit)",
            "Td(n)",
            "Px(12px)",
            "Py(12px)",
            "Pos(r)",
            "buttonsGroup--selected_C(#ffffff)"
        ];
    }

    get css() {
        return [
            "C(color)",
            "C(primary):h",
            "D(ib)",
            "BdEnd Bdc(border-color-2)",
            "Bdw(0):lc"
        ];
    }

    render() {
        const { list, className = "" } = this.props;
        return (
            <div className={cs("D(f) Ai(c) Ac(c)", className)}>
                <Menu className="Bd Bdc(border-color-2) Bgc(#fff) Bdrs(radius) D(ib) Fz(1rem) Ov(h)">
                    {list.map((value, index) => (
                        <MenuItem
                            key={index}
                            linkClassName={cs(this.linkCss)}
                            className={cs(this.conditionCss(value), this.css)}
                            onClick={this.handleClick(value)}
                        >
                            <Ink />
                            {capitalize(value)}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        );
    }
}
