import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";
import { isEqual } from "lodash";

import "react-tippy/dist/tippy.css";

import { shortTitle } from "./utility";

export default class MediaTitle extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        number: PropTypes.number,
        maxChar: PropTypes.number
    };

    static defaultProps = {
        maxChar: 60
    };

    constructor(props) {
        super(props);
        const { title, maxChar } = this.props;
        const short = shortTitle(title, maxChar);

        this.state = {
            isShort: title !== short
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { prevProps } = prevState;
        if (isEqual(nextProps, prevProps)) {
            return null;
        }

        const { title, maxChar } = nextProps;
        const short = shortTitle(title, maxChar);
        const isShort = title !== short;

        return {
            longTitle: title,
            title: short,
            isShort,
            prevProps: nextProps
        };
    }

    render() {
        const { number } = this.props;
        const { longTitle, title, isShort } = this.state;

        if (!isShort) {
            return (
                <>
                    {number && <span className="Fw(b) C(#999)">{number} - </span>} {title}
                </>
            );
        }
        return (
            <Tooltip
                popperOptions={{
                    modifiers: {
                        preventOverflow: {
                            enabled: false
                        },
                        hide: { enabled: false }
                    }
                }}
                html={<div className="Ta(start)">{longTitle}</div>}
                position="bottom-start"
                trigger="mouseenter"
                className="Wow(bw) Wob(ba) Hy(a)">
                {number && <span className="Fw(b) C(#999)">{number}  - </span>} {title}
            </Tooltip>
        );
    }
}
