import React, { Component } from "react";
import cs from "classnames";
import { DefaultPlayer as Player } from "react-html5video";

import "react-html5video/dist/styles.css";

import { cdn } from "../components/utility";

export default class Video extends Component {
    render() {
        const { className, onClick, poster, posterOptions, ...props } = this.props;

        const css = [
            "Ov(h)",
            "D(f)",
            "Ai(c)",
            "Ac(c)",
            "Jc(c)",
            "H(100%)"
        ];

        return (
            <div className={cs(css, className)} onClick={onClick}>
                <div className="H(100%) W(100%) VideoJsContainer">
                    <Player style={{height: "100%"}} className="StretchedBox--landscape" poster={this.toCDN(poster)} {...props} />
                </div>
            </div>
        );
    }

    toCDN = (url, shceme = "") => {
        const { posterOptions } = this.props;

        return cdn.url(url, shceme, posterOptions);
    };
}
