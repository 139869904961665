import React, { Component } from "react";
import cs from "classnames";
import ContentEditable from "react-contenteditable";

import { childCss as inputGroupCss } from "./InputGroup";
import { childCss as formControlCss } from "./FormControl";

export default class TextArea extends Component {
    componentDidMount() {
        document.execCommand("defaultParagraphSeparator", false, "p");
    }

    render() {
        const {
            className,
            value,
            html,
            ...props
        } = this.props;

        const css = [
            ...formControlCss,
            ...inputGroupCss,

            "Px(12px)",
            "Py(12px)",
            "C(color)",
            "Bgi(n)",
            "Bgcp(pb)",
            "Bd",
            "Bdc(border-color-2)",
            "Bdrs(radius)",
            "D(b)",
            "W(100%)",

            "O(n):f",
            "Trs(inputFocuseTrs)",
            "Bdc(inputBorderColorHover):f",
            "Bxsh(inputBoxShadowHover):f",
            "C(placeholderColor)::ph",

            "Cur(p)"
        ];

        return (
            <ContentEditable
                className={cs(css, className)}
                html={value}
                {...props}
            />
        );
    }
}
