import React, { Component } from "react";
import cs from "classnames";
import "../assets/fonts/icon.scss";

export default class Icon extends Component {
    render() {
        const { icon, className, ...props } = this.props;
        let css = [];
        if (icon) {
            css = ["ic", "ic-" + icon];
        }
        return (
            <i className={cs(css, className)} {...props} />
        );
    }
}
