import React, { useEffect, useState, useRef } from "react";
import { ButtonsGroup, InputGroup, InputTimePicker, secontToMoment } from "..";
import Field from "./Field";
import { SET_CUSTOME, DEFAULT, INHERITE } from "./util";

const durationButtons = [INHERITE, SET_CUSTOME];

const DurationField = (props) => {
    const {
        type,
        defaultDuration,
        duration,
        onChange,
        getPopupContainer,
        durationInputRef = () => {},
        fieldProps
    } = props;

    const [durationButton, setDurationButton] = useState(
        duration === 0 ? INHERITE : SET_CUSTOME
    );
    const isFirstRun = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        onChange(0);
    // eslint-disable-next-line
  }, [durationButton]);

    const handelResetDuration = () => {
        onChange(defaultDuration);
    };

    return (
        <Field title="Duration" {...fieldProps}>
            <div className="D(f) Fld(c) Fld(r)--sm">
                <ButtonsGroup
                    list={durationButtons}
                    type={durationButton}
                    onChange={setDurationButton}
                />
                {(durationButton === SET_CUSTOME || durationButton === DEFAULT) && (
                    <div className="Mstart(8px)--sm Mstart(0) Mt(32px) Mt(0)--sm W(a)">
                        {type === "video" ? (
                            <InputGroup className="Mb(0)!">
                                <InputTimePicker
                                    className="Fz(1rem) Mb(0)! W(90px) Bdrs(radiusLeft)!"
                                    showSecond={true}
                                    value={secontToMoment(duration)}
                                    onChange={onChange}
                                    getPopupContainer={getPopupContainer}
                                    inputRef={durationInputRef}
                                />
                                <InputGroup.Button
                                    onClick={handelResetDuration}
                                    buttonClassName={
                                        "Px(12px) Bgc(#fff) C(primary):h Bdrs(radius)"
                                    }
                                >
                  Reset
                                </InputGroup.Button>
                            </InputGroup>
                        ) : (
                            <InputTimePicker
                                className="Fz(1rem) Mb(0)! W(90px)"
                                showSecond={true}
                                value={secontToMoment(duration)}
                                onChange={onChange}
                                getPopupContainer={getPopupContainer}
                                inputRef={durationInputRef}
                            />
                        )}
                        {/* <InputGroup className="Mb(0)!">
              <InputTimePicker
                className="Fz(1rem) Mb(0)!"
                showSecond={true}
                value={secontToMoment(duration)}
                onChange={onChange}
                getPopupContainer={getPopupContainer}
                inputRef={durationInputRef}
              />
              {type === "video" && defaultDuration !== duration && (
                <InputGroup.Button onClick={handelResetDuration}>
                  Reset
                </InputGroup.Button>
              )}
            </InputGroup> */}
                    </div>
                )}
            </div>
        </Field>
    );
};

export default DurationField;
