import {RPC_LIB_FETCHED, RPC_LIB_FETCHING, RPC_LIB_LOADED} from "../actions";


export default function(state = {data: [], loaded: false, fetching: false}, action) {
    switch (action.type) {
    case RPC_LIB_FETCHED:
        const data = action.data;
        return {loaded: true, data: data, fetching: false };
    case RPC_LIB_FETCHING:
        return {...state, fetching: true};
    case RPC_LIB_LOADED:
        return {...state, loaded: true};
    default:
        return state;
    }
}
