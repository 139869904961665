import {
    TAGS_COLOUR_FAILED,
    TAGS_COLOUR_FETACHED,
    TAGS_COLOUR_FETCHING } from "../actions";

const defaultState = {
    tags: {},
    isFetcing: false,
    error: null
};

export default function(state = defaultState, action) {
    const { tags } = state;
    switch (action.type) {
    case TAGS_COLOUR_FETCHING :
        return {
            ...state,
            isFetcing: true,
            error: null
        };
    case TAGS_COLOUR_FAILED :
        return {
            ...state,
            isFetcing: false,
            error: action.error
        };
    case TAGS_COLOUR_FETACHED:
        return {
            tags: { ...tags, ...action.tags },
            isFetcing: false,
            error: null
        };
    default:
        return state;
    }
}
