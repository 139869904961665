import React from "react";
import { isEmpty, merge, upperFirst } from "lodash";
import {
    initiateApp,
    setConfigs,
    setDescription,
    setName,
    setDuration,
    setContents,
    addActionButton,
    closeModal,
    openModal,
    toggleOrderMode,
    renewAuth,
    getTagsColour,
    setBeforeSaveData
} from "./store/actions";
import {
    Toast,
    Confirm,
    SettingsModal
} from "./components";

import { parseUrl } from "./components/utility";
import { POSITION, TYPE } from "./components/toast";

/**
 *
 *
 * @param {*} context
 * @returns
 */
const ApiActions = (context) => {
    return {
        toast: (content, toastId = null, argOptions = {}) => {
            const options = merge({
                type: TYPE.INFO,
                position: POSITION.BOTTOM_RIGHT
            }, argOptions);

            if (toastId !== null) {
                return Toast.notify.update(toastId, merge(options, { render: content }));
            }
            return Toast.notify(content, options);
        },

        alert: (title, message = "", buttonLabel = "OK", onClick = () => true) => {
            const { dispatch } = context.props;
            return dispatch(openModal(<Confirm
                title={title}
                confirmLabel={buttonLabel}
                cancelLabel={false}
                onConfirm={onClick}
                onCancel={() => true}
            > {message}</Confirm>, { isFixed: false, size: "md" }));
        },

        openModal: (modal, configs = {}) => {
            const { dispatch } = context.props;
            return dispatch(openModal(modal, configs));
        },

        reloadApp: (configs) => {
            const { data } = context.props;
            if (isEmpty(data)) {
                context.actions.load(configs);
            }

            // new app
            if (isEmpty(configs)) {
                context.actions.load(parseUrl(window.location.href));
            }
        },

        closeModal: (cb = true) => {
            const { dispatch } = context.props;
            if ((typeof cb === "function" && cb()) || cb === true) {
                return dispatch(closeModal());
            }
        },

        updateConfigs: (configs, contents = null) => {
            const {
                dispatch
            } = context.props;

            if (contents !== null) {
                dispatch(setContents(contents));
            }

            return dispatch(setConfigs(configs));
        },

        updateDuration: (duration) => {
            const {
                dispatch
            } = context.props;

            return dispatch(setDuration(duration));
        },

        updateName: (name) => {
            const {
                dispatch,
                data: { apps_type }
            } = context.props;

            const typeName = apps_type === "template" ? "loop" : apps_type;

            document.title = `${upperFirst(typeName)} - ${name}`;
            return dispatch(setName(name));
        },

        confirm: (
            title,
            message,
            confirmProps = {}
        ) => {
            const { dispatch } = context.props;
            const defaultPorps = {
                buttonLabel: "OK",
                cancelLabel: "Cancel",
                onConfirm: () => true,
                onCancel: () => true
            };

            const props = {...defaultPorps, ...confirmProps};
            return dispatch(openModal(<Confirm
                title={title}
                {...props}
            > {message}</Confirm>, { isFixed: false, size: "md" }
            ));
        },

        addActionButton: (key, ob, callBack) => {
            const { dispatch, sdk: { app } } = context.props;

            return dispatch(addActionButton(key, ob, callBack, app));
        },

        setBeforeSaveData: (rules) => {
            const {
                dispatch
            } = context.props;
            return dispatch(setBeforeSaveData(rules));
        },

        toggleOrderMode: () => {
            const { dispatch } = context.props;
            return dispatch(toggleOrderMode());
        },

        updateDescription: (description) => {
            const {
                dispatch
            } = context.props;

            return dispatch(setDescription(description));
        },

        renewAuthAction: () => {
            const { dispatch } = context.props;
            return dispatch(renewAuth());
        },

        getTagsColour: (tags) => {
            const { dispatch } = context.props;
            return dispatch(getTagsColour(tags));
        },

        load: async (configs = {}) => {
            const { dispatch } = context.props;
            const sdk = await dispatch(initiateApp(configs, context.actions.toast));
            const type = sdk.type;
            const title = type === "template" ? "Loop" : upperFirst(type);
            document.title = `${title} - ${sdk.name}`;
            (async () => {
                const appName = type === "vms2" ? "vms" : type;
                const { default: app, SettingsPage } = await (await import("./applications/" + appName));
                context.loadApp(app, SettingsPage);
            })();
        },

        openSettingsModal: (SettingsPage) => () => {
            const { globalState } = context.props;
            if (!SettingsPage) {
                context.actions.openModal(
                    <SettingsModal
                        actions={context.actions}
                        {...globalState}
                    />,
                    { isFixed: false, size: "sm" });
            } else {
                context.actions.openModal(
                    <SettingsPage
                        actions={context.actions}
                        {...globalState}
                    />,
                    { isFixed: false, size: "sm" });
            }
        }
    };
};
export default ApiActions;
