import React, { Component, cloneElement } from "react";
import PropTypes from "prop-types";
import cs from "classnames";

import Footter from "./Footter";
import Header from "./Header";

export default class Content extends Component {
    static contextTypes = {
        $modalContainer: PropTypes.object
    };

    static propTypes = {
        containerRef: PropTypes.func
    };

    static defaultProps = {
        containerRef: () => {}
    };

    render() {
        const {
            children,
            containerRef,
            className,
            ...props
        } = this.props;

        let footter = null;
        let header = null;
        const { isFixed } = this.context.$modalContainer;

        const boundChildren = React.Children.map(children, child => {
            if (child === null) {
                return;
            }
            if (child.type === Footter) {
                const { className: childClassName } = child.props;
                footter = cloneElement(child, {
                    className: cs(childClassName, isFixed === true ? "Lh(3.5)" : "")
                });
            } else if (child.type === Header) {
                const { className: childClassName } = child.props;
                header = cloneElement(child, {
                    className: cs(childClassName)
                });
            } else {
                return child;
            }
        });

        const css = {
            "StretchedBox": isFixed,
            "D(f)": isFixed,
            "Fld(c)": isFixed
        };

        const cssContainer = {
            "Flxg(2)": isFixed,
            "Ov(a)": isFixed,
            "Flxb(0)": isFixed
        };

        return (
            <div className={cs(css, className)} {...props}>
                {header}
                <div className={cs(cssContainer)} ref={containerRef}>
                    {boundChildren}
                </div>
                {footter}
            </div>
        );
    }
}
