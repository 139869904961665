import React, { Component } from "react";
import cs from "classnames";

import { Card } from ".";

export default class EmptyStateCard extends Component {
    render() {
        const { className, ...props } = this.props;
        return (
            <Card
                className={cs(
                    "D(f) Ai(c) Jc(c) My(12px) Py(32px)! Px(16px)! Cur(p) Ta(c) Bdrs(radius-2)!",
                    className
                )}
                {...props}
            />
        );
    }
}
