import {
    FETCH_DATA,
    ORDER_MODE,
    FETCH_DATA_FAILED,
    SAVE_DATA,
    SAVE_DATA_FAILED,
    SAVE_DATA_SUCCEED,
    SET_NAME,
    SET_DESCRIPTION,
    SET_DURATION,
    SET_CONFIGS,
    SET_BEFORE_SAVE_DATA,
    APP_READY,
    ON_ERROR,
    EXIT_APP,
    EXIT_APP_DONE,
    OPEN_MODAL,
    CLOSE_MODAL,
    RENEW_AUTH_REQUESTED,
    RENEW_AUTH

} from "../actions";

const defaultState = {
    isFetching: true,
    isSaving: false,
    exitRequested: false,
    didInvalidate: false,
    app: null,
    modal: null,
    renewAuth: false,
    orderMode: false
};

export default function(state = defaultState, action) {
    const {
        app
    } = state;
    switch (action.type) {
    case APP_READY:

        return {
            ...state,
            isFetching: false,
            app: action.app
        };
    case FETCH_DATA:
        return {
            ...state,
            isFetching: true,
            configs: action.configs
        };
    case SET_DESCRIPTION:
        const description = action.description;
        app.description = description;
        return state;

    case SET_NAME:
        const name = action.name;
        app.name = name;
        return state;

    case SET_DURATION:
        const duration = action.duration;
        app.duration = duration;
        return state;

    case SET_CONFIGS:
        const configs = action.configs;
        app.configs = configs;
        return state;

    case SET_BEFORE_SAVE_DATA:
        const beforeSaveData = action.beforeSaveData;
        app.beforeSaveData = beforeSaveData;
        return state;
    case SAVE_DATA:
        return {
            ...state,
            isSaving: true
        };
    case SAVE_DATA_SUCCEED:
        return {
            ...state,
            isSaving: false
        };
    case FETCH_DATA_FAILED:
        return {
            ...state,
            isSaving: false,
            didInvalidate: true,
            error: action.error,
            isFetching: false,
            app: action.app
        };
    case SAVE_DATA_FAILED:
    case ON_ERROR:
        return {
            ...state,
            isSaving: false,
            isFetching: false,
            didInvalidate: true,
            error: action.error
        };
    case EXIT_APP:
        return {
            ...state,
            exitRequested: true
        };
    case EXIT_APP_DONE:
        return {
            ...state,
            exitRequested: false
        };
    case OPEN_MODAL:
        return {
            ...state,
            modal: {
                content: action.content,
                isFixed: action.isFixed,
                fullScreen: action.fullScreen,
                size: action.size
            }
        };
    case CLOSE_MODAL:
        return {
            ...state,
            modal: null
        };
    case RENEW_AUTH:
        return {
            ...state,
            renewAuth: true
        };

    case RENEW_AUTH_REQUESTED:
        return {
            ...state,
            renewAuth: false
        };
    case ORDER_MODE:
        return {
            ...state,
            orderMode: action.orderMode
        };
    default:
        return state;
    }
}
